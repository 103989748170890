export const validateFirstLetterId = (id: string | undefined | null): boolean => {
  const item = id?.charAt(0);
  return Boolean(item) && /^[a-z]?$/.test(item!) && item === item?.toLowerCase();
};

export const validateLastLetterId = (id: string | undefined | null): boolean => {
  const item = id?.slice(-1);
  return Boolean(item) && /^[a-z0-9]?$/.test(item!) && item === item?.toLowerCase();
};

export const validateContainLetterId = (id: string | undefined | null): boolean =>
  Boolean(id) && /^[a-z0-9-]+$/.test(id!);

export const validateLengthId = (id: string | undefined | null): boolean => {
  return Boolean(id) && Boolean(id && id?.length > 0) && Boolean(id && id?.length < 61);
};
