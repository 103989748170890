import { IStyle } from '@fluentui/react';
import { IH2OTheme, useClassNames, useTheme } from '@h2oai/ui-kit';

import { ClassNamesFromIStyles } from '../../utils/models';
import NavigationWrapper from './NavigationWrapper';

interface IBatchScoringStyles {
  container: IStyle;
  title: IStyle;
}

const batchScoringStyles = (theme: IH2OTheme): IBatchScoringStyles => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      padding: '10px 40px 20px 40px',
      margin: '0px 20px',
    },
    title: {
      marginTop: 0,
      // TODO: Remove once theme is used somewhere else.
      color: theme.semanticColors?.textPrimary,
    },
  };
};

const BatchScoring = () => {
  const theme = useTheme(),
    classNames = useClassNames<IBatchScoringStyles, ClassNamesFromIStyles<IBatchScoringStyles>>(
      'batchScoring',
      batchScoringStyles(theme)
    );

  return (
    <NavigationWrapper>
      <div className={classNames.container}>
        <h1 className={classNames.title}>Batch scoring</h1>
      </div>
    </NavigationWrapper>
  );
};

export default BatchScoring;
