import { ISpinButtonStyles, ITextStyles, Label, SpinButton, Stack, Text } from '@fluentui/react';
import { useThemeStyles } from '@h2oai/ui-kit';
import { SyntheticEvent } from 'react';

import { LabelIconTooltip } from '../LabelIconTooltip/LabelIconTooltip';
import {
  fieldLabelStyles,
  fluentNumberFieldDefault,
  helpTextDefault,
  requiredFieldLabelStyles,
} from './fluentNumberField.styles';

export interface IFluentNumberFieldProps {
  disabled?: boolean;
  id?: string;
  fixed?: boolean;
  label?: string;
  min?: number;
  max?: number;
  onChange: (event: SyntheticEvent<HTMLElement, Event>, newValue?: string | undefined) => void;
  onIncrement?: (value: string, event: SyntheticEvent<HTMLElement, Event>) => void;
  onDecrement?: (value: string, event: SyntheticEvent<HTMLElement, Event>) => void;
  value?: string;
  tooltip?: string;
  styles?: any;
  step?: number;
  required?: boolean;
}

export const FluentNumberField = (props: IFluentNumberFieldProps) => {
  const { label, max, min, styles, tooltip, fixed, required } = props;
  const fprops = {
    ...props,
    label: undefined,
    disabled: fixed ? true : props.disabled,
    ...{
      styles: useThemeStyles<Partial<ISpinButtonStyles>>(fluentNumberFieldDefault, styles),
    },
    onKeyDown: (e: any) => {
      // allow only backspace, tab, arrow keys and numbers
      if ([8, 9, 37, 38, 39, 40, 41].includes(e.keyCode)) return;
      if (!/^[0-9]+$/.test(e.key)) e.preventDefault();
    },
  };
  const textStyles = useThemeStyles<Partial<ITextStyles>>(helpTextDefault, {});
  const labelStyles = useThemeStyles<Partial<ITextStyles>>(required ? requiredFieldLabelStyles : fieldLabelStyles, {});
  const labelItem = (
    <Stack horizontal styles={labelStyles}>
      {label}
    </Stack>
  );

  return (
    <Stack>
      {label && (
        <>
          <Label styles={{ root: { position: 'relative' } }}>
            {!fixed ? (
              <Text styles={textStyles} data-test="aiem-fluent-number-field-help-text">
                {min !== undefined && `Min: ${min}`}
                {min !== undefined && max !== undefined && ' | '}
                {max !== undefined && `Max: ${max}`}
              </Text>
            ) : null}
            {tooltip ? <LabelIconTooltip label={labelItem} tooltip={tooltip} /> : labelItem}
          </Label>
        </>
      )}
      <SpinButton {...(fprops as any)} />
    </Stack>
  );
};
