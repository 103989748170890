// @generated by protoc-gen-grpc-gateway-es v0.1.6 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/notebook/v1/kernel_template_service.proto (package ai.h2o.notebook.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { KernelTemplate } from "./kernel_template_pb";
import { RPC } from "../../../../runtime";

/**
 * Request message for `CreateKernelTemplate`.
 *
 * @generated from message ai.h2o.notebook.v1.CreateKernelTemplateRequest
 */
export type CreateKernelTemplateRequest = {
/**
 * Required. The resource name of the workspace to associate with the KernelTemplate.
 * Format is `workspaces/*`.
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * Required. The KernelTemplate resource to create.
 *
 * @generated from field: ai.h2o.notebook.v1.KernelTemplate kernel_template = 2;
 */
kernelTemplate: KernelTemplate;
/**
 * Optional. Specify the KernelTemplate ID, which will become a part of the KernelTemplate
 * resource name. If not specified, the server will generate one.
 *
 * It must:
 * - contain 1-63 characters
 * - contain only lowercase alphanumeric characters or hyphen ('-')
 * - start with an alphabetic character
 * - end with an alphanumeric character
 *
 * This matches a regular expression: ^[a-z]([a-z0-9-]{0,61}[a-z0-9])?$
 *
 * @generated from field: string kernel_template_id = 3;
 */
kernelTemplateId?: string;
}
;
/**
 * Response message for `CreateKernelTemplate`.
 *
 * @generated from message ai.h2o.notebook.v1.CreateKernelTemplateResponse
 */
export type CreateKernelTemplateResponse = {
/**
 * The created KernelTemplate.
 *
 * @generated from field: ai.h2o.notebook.v1.KernelTemplate kernel_template = 1;
 */
kernelTemplate?: KernelTemplate;
}
;
/**
 * Request message for `GetKernelTemplate`.
 *
 * @generated from message ai.h2o.notebook.v1.GetKernelTemplateRequest
 */
export type GetKernelTemplateRequest = {
/**
 * Required. The name of the KernelTemplate to retrieve.
 * Format is `workspaces/*\/kernelTemplates/*`.
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for `GetKernelTemplate`.
 *
 * @generated from message ai.h2o.notebook.v1.GetKernelTemplateResponse
 */
export type GetKernelTemplateResponse = {
/**
 * The requested KernelTemplate.
 *
 * @generated from field: ai.h2o.notebook.v1.KernelTemplate kernel_template = 1;
 */
kernelTemplate?: KernelTemplate;
}
;
/**
 * Request message for listing KernelTemplates.
 *
 * @generated from message ai.h2o.notebook.v1.ListKernelTemplatesRequest
 */
export type ListKernelTemplatesRequest = {
/**
 * Required. The resource name of the workspace to associate with the KernelTemplate.
 * Format is `workspaces/*`.
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * Optional. Maximum number of KernelTemplates to return in a response.
 * If unspecified (or set to 0), at most 50 KernelTemplates will be returned.
 * The maximum value is 1000; values above 1000 will be coerced to 1000.
 *
 * @generated from field: int32 page_size = 2;
 */
pageSize?: number;
/**
 * Optional. Leave unset to receive the initial page.
 * To list any subsequent pages use the value of 'next_page_token'
 * returned from the ListKernelTemplatesResponse.
 *
 * @generated from field: string page_token = 3;
 */
pageToken?: string;
}
;
/**
 * Response message for ListKernelTemplates.
 *
 * @generated from message ai.h2o.notebook.v1.ListKernelTemplatesResponse
 */
export type ListKernelTemplatesResponse = {
/**
 * Collection of KernelTemplates.
 *
 * @generated from field: repeated ai.h2o.notebook.v1.KernelTemplate kernel_templates = 1;
 */
kernelTemplates?: KernelTemplate[];
/**
 * Used to retrieve the next page of results.
 * When unset, no further items are available (this response was the last page).
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
}
;
/**
 * Request message for deleting an existing KernelTemplate.
 *
 * @generated from message ai.h2o.notebook.v1.DeleteKernelTemplateRequest
 */
export type DeleteKernelTemplateRequest = {
/**
 * Required. KernelTemplate resource name.
 * Format is `workspaces/*\/kernelTemplates/*`.
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for DeleteKernelTemplate.
 *
 * @generated from message ai.h2o.notebook.v1.DeleteKernelTemplateResponse
 */
export type DeleteKernelTemplateResponse = {
}
;
/**
 * Creates a new KernelTemplate.
 *
 * @generated from rpc ai.h2o.notebook.v1.KernelTemplateService.CreateKernelTemplate
 */
export const KernelTemplateService_CreateKernelTemplate = new RPC<CreateKernelTemplateRequest, CreateKernelTemplateResponse>("POST", "/v1/{parent=workspaces/*}/kernelTemplates", "kernelTemplate");
/**
 * Returns a specific KernelTemplate.
 *
 * @generated from rpc ai.h2o.notebook.v1.KernelTemplateService.GetKernelTemplate
 */
export const KernelTemplateService_GetKernelTemplate = new RPC<GetKernelTemplateRequest, GetKernelTemplateResponse>("GET", "/v1/{name=workspaces/*/kernelTemplates/*}");
/**
 * Returns a collection of KernelTemplates.
 *
 * @generated from rpc ai.h2o.notebook.v1.KernelTemplateService.ListKernelTemplates
 */
export const KernelTemplateService_ListKernelTemplates = new RPC<ListKernelTemplatesRequest, ListKernelTemplatesResponse>("GET", "/v1/{parent=workspaces/*}/kernelTemplates");
/**
 * Deletes a KernelTemplate.
 *
 * @generated from rpc ai.h2o.notebook.v1.KernelTemplateService.DeleteKernelTemplate
 */
export const KernelTemplateService_DeleteKernelTemplate = new RPC<DeleteKernelTemplateRequest, DeleteKernelTemplateResponse>("DELETE", "/v1/{name=workspaces/*/kernelTemplates/*}");
