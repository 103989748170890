// @generated by protoc-gen-grpc-gateway-es v0.1.6 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/notebook/v1/kernel_image.proto (package ai.h2o.notebook.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { ImagePullPolicy } from "./base_types_pb";

/**
 * KernelImage represents a Docker image that is used to run a kernel.
 * An existing KernelImage must be specified when creating a new Kernel or NotebookKernelSpec.
 *
 * @generated from message ai.h2o.notebook.v1.KernelImage
 */
export type KernelImage = {
/**
 * Output only. Resource name.
 * Format is `workspaces/*\/kernelImages/*`.
 *
 * @generated from field: string name = 1;
 */
name?: string;
/**
 * Optional. Human-readable name.
 *
 * @generated from field: string display_name = 2;
 */
displayName?: string;
/**
 * Required. Type of the KernelImage.
 *
 * @generated from field: ai.h2o.notebook.v1.KernelImage.Type type = 3;
 */
type: KernelImage_Type;
/**
 * Required. Docker image name.
 * For example: "gcr.io/vorvan/h2oai/h2o-kernel-py:0.3.2"
 *
 * @generated from field: string image = 4;
 */
image: string;
/**
 * Optional. Whether the KernelImage is disabled.
 * Disabled KernelImage cannot be used for creating new Kernel.
 *
 * @generated from field: bool disabled = 5;
 */
disabled?: boolean;
/**
 * Optional. Specifies the policy that determines how the Docker image for the KernelImage should be pulled.
 * When unset, server will choose a default one.
 *
 * @generated from field: ai.h2o.notebook.v1.ImagePullPolicy image_pull_policy = 6;
 */
imagePullPolicy?: ImagePullPolicy;
/**
 * Optional. A list of Kubernetes secret references used for authenticating to private container registries or repositories.
 * These secrets provide the necessary credentials to pull Docker images from private repositories.
 * Each entry in this list should be the name of a Kubernetes secret configured in the cluster.
 *
 * @generated from field: repeated string image_pull_secrets = 7;
 */
imagePullSecrets?: string[];
/**
 * Output only. Time when the KernelImage was created.
 *
 * @generated from field: google.protobuf.Timestamp create_time = 100;
 */
createTime?: string;
/**
 * Output only. Time when the KernelImage was last updated.
 *
 * @generated from field: optional google.protobuf.Timestamp update_time = 101;
 */
updateTime?: string;
}
;
/**
 * KernelImage type.
 *
 * @generated from enum ai.h2o.notebook.v1.KernelImage.Type
 */
export enum KernelImage_Type {
/**
 * KernelImage type is unspecified or unknown.
 *
 * @generated from enum value: TYPE_UNSPECIFIED = 0;
 */
UNSPECIFIED = "TYPE_UNSPECIFIED",

/**
 * Python KernelImage.
 *
 * @generated from enum value: TYPE_PYTHON = 1;
 */
PYTHON = "TYPE_PYTHON",

/**
 * R KernelImage.
 *
 * @generated from enum value: TYPE_R = 2;
 */
R = "TYPE_R",

/**
 * Python with Spark KernelImage.
 *
 * @generated from enum value: TYPE_SPARK_PYTHON = 3;
 */
SPARK_PYTHON = "TYPE_SPARK_PYTHON",

/**
 * R with Spark KernelImage.
 *
 * @generated from enum value: TYPE_SPARK_R = 4;
 */
SPARK_R = "TYPE_SPARK_R",
}
