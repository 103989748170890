import { PanelType, Stack, Text } from '@fluentui/react';
import { Button, FontSizes, KeyValuePairEditor, Panel, TextWithCopy, defaultPalette } from '@h2oai/ui-kit';
import { useMemo } from 'react';

import { EntityType } from '../../../components/AdminSettings/Entity/constants';
import { booleanToReadable, bytesToGiB, getResourceId } from '../../../components/AdminSettings/Entity/utils';
import { getBestDateTimeUnitInfoPair, getIdFromName } from '../../../components/AdminSettings/utils';
import { stylesPanel } from '../../../components/AIEnginesPage/components/AIEMPanel/AIEMPanel';
import { CodeArea } from '../../../components/CodeArea/CodeArea';
import { imagePullPolicyMap, kernelImageTypeMap } from '../../../notebook/constants';
import type { KernelImage } from '../../../notebook/gen/ai/h2o/notebook/v1/kernel_image_pb';
import type { KernelTemplate } from '../../../notebook/gen/ai/h2o/notebook/v1/kernel_template_pb';
import type { NotebookKernelSpec } from '../../../notebook/gen/ai/h2o/notebook/v1/notebook_kernel_spec_pb';

const viewLabel = { styles: { root: { fontSize: FontSizes.xsmall, color: defaultPalette?.gray500, width: '40%' } } };
const viewValue = {
  styles: {
    root: {
      fontSize: FontSizes.xsmall,
      color: defaultPalette?.gray900,
      whiteSpace: 'nowrap',
      maxWidth: '60%',
      overflow: 'hidden' as unknown as 'hidden', // not sure why this is needed, but it is
    },
  },
};

const renderElement = (condition: any, renderItem?: JSX.Element | string) => {
  if (!renderItem) return condition ? condition : '-';
  return condition ? renderItem : '-';
};

const renderKernelImageEntityView = (kernelImage: KernelImage) => {
  if (!kernelImage) {
    return <></>;
  }

  return (
    <>
      <Stack horizontal>
        <Text {...viewLabel}>Display name</Text>
        <Text {...viewValue}>{kernelImage.displayName}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>ID</Text>
        <Text {...viewValue}>{getIdFromName(kernelImage.name || '')}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>Type</Text>
        <Text {...viewValue}>
          {renderElement(kernelImageTypeMap[kernelImage.type], kernelImageTypeMap[kernelImage.type])}
        </Text>
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>Image</Text>
        <Text {...viewValue} title={kernelImage.image}>
          {renderElement(kernelImage.image, <TextWithCopy text={kernelImage.image} />)}
        </Text>
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>Disabled</Text>
        <Text {...viewValue}>{booleanToReadable(kernelImage.disabled)}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>Image pull policy</Text>
        <Text {...viewValue}>
          {renderElement(kernelImage.imagePullPolicy, imagePullPolicyMap[kernelImage.imagePullPolicy || ''])}
        </Text>
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>Image pull secrets</Text>
        <Text {...viewValue}>{kernelImage.imagePullSecrets?.join(', ') || '-'}</Text>
      </Stack>
    </>
  );
};
const renderKernelTemplateEntityView = (kernelTemplate: KernelTemplate) => {
  if (!kernelTemplate) {
    return <></>;
  }
  const [numberProp, unitProp] = getBestDateTimeUnitInfoPair(kernelTemplate.maxIdleDuration);

  return (
    <>
      <Stack horizontal>
        <Text {...viewLabel}>Name</Text>
        <Text {...viewValue}>{getResourceId(kernelTemplate.name)}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>MilliCPU Request</Text>
        <Text {...viewValue}>{renderElement(kernelTemplate.milliCpuRequest)}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>MilliCPU Limit</Text>
        <Text {...viewValue}>{renderElement(kernelTemplate.milliCpuLimit)}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>GPU Resource</Text>
        <Text {...viewValue}>{renderElement(kernelTemplate.gpuResource)}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>GPU</Text>
        <Text {...viewValue}>{renderElement(kernelTemplate.gpu)}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>Memory Request</Text>
        <Text {...viewValue}>
          {renderElement(kernelTemplate.memoryBytesRequest, bytesToGiB(kernelTemplate.memoryBytesRequest))}
        </Text>
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>Memory Limit</Text>
        <Text {...viewValue}>
          {renderElement(kernelTemplate.memoryBytesLimit, bytesToGiB(kernelTemplate.memoryBytesLimit))}
        </Text>
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>Storage</Text>
        <Text {...viewValue}>
          {renderElement(kernelTemplate.storageBytes, bytesToGiB(kernelTemplate.storageBytes))}
        </Text>
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>Storage Class Name</Text>
        <Text {...viewValue}>{renderElement(kernelTemplate.storageClassName)}</Text>
      </Stack>
      <Stack>
        <Text {...viewLabel} style={{ marginBottom: 8 }}>
          Environmental Variables
        </Text>
        <KeyValuePairEditor config={kernelTemplate.environmentalVariables || {}} onUpdateConfig={() => null} readOnly />
      </Stack>
      <Stack>
        <Text {...viewLabel} style={{ marginBottom: 8 }}>
          YAML Pod Template Spec
        </Text>
        <CodeArea cols={30} rows={15} readOnly defaultValue={kernelTemplate.yamlPodTemplateSpec} />
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>Disabled</Text>
        <Text {...viewValue}>{booleanToReadable(kernelTemplate.disabled)}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>Max Idle Duration</Text>
        <Text {...viewValue}>
          {numberProp} {numberProp === 1 ? unitProp?.singular : unitProp?.plural}
        </Text>
      </Stack>
    </>
  );
};
const renderKernelSpecEntityView = (kernelSpec: NotebookKernelSpec) => {
  if (!kernelSpec) {
    return <></>;
  }

  return (
    <>
      <Stack horizontal>
        <Text {...viewLabel}>Display name</Text>
        <Text {...viewValue}>{kernelSpec.displayName}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>ID</Text>
        <Text {...viewValue}>{getIdFromName(kernelSpec.name || '')}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>Kernel Image</Text>
        <Text {...viewValue}>{getResourceId(kernelSpec.kernelImage)}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>Kernel Template</Text>
        <Text {...viewValue}>{getResourceId(kernelSpec.kernelTemplate)}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...viewLabel}>Disabled</Text>
        <Text {...viewValue}>{booleanToReadable(kernelSpec.disabled)}</Text>
      </Stack>
    </>
  );
};

export interface NotebookSettingsViewPanelProps<EntityModel> {
  item?: EntityModel;
  onDismiss: () => any;
  panelTitle: string;
  type: EntityType;
}

export const NotebookSettingsViewPanel = <EntityModel,>(props: NotebookSettingsViewPanelProps<EntityModel>) => {
  const { item, type, onDismiss, panelTitle } = props;
  const content = useMemo(() => {
    switch (type) {
      case EntityType.KernelImage:
        return renderKernelImageEntityView(item as unknown as KernelImage);
      case EntityType.KernelTemplate:
        return renderKernelTemplateEntityView(item as unknown as KernelTemplate);
      case EntityType.KernelSpec:
        return renderKernelSpecEntityView(item as unknown as NotebookKernelSpec);

      default:
        return null;
    }
  }, [type, item]);

  return (
    <Panel
      isLightDismiss
      customWidth="500px"
      headerText={panelTitle}
      isFooterAtBottom
      isOpen={true}
      onDismiss={onDismiss}
      type={PanelType.custom}
      styles={stylesPanel}
      onRenderFooterContent={() => {
        return (
          <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="end">
            <Button text="Close" onClick={onDismiss} />
          </Stack>
        );
      }}
    >
      <Stack tokens={{ childrenGap: 20 }}>{content}</Stack>
    </Panel>
  );
};
