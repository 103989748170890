import { MessageBarType } from '@fluentui/react';
import { useToast } from '@h2oai/ui-kit';
import React from 'react';

import { RoleBinding } from '../../authz/gen/ai/h2o/authorization/v1/role_binding_pb';
import { Role } from '../../authz/gen/ai/h2o/authorization/v1/role_pb';
import { useAuthzService } from '../../authz/hooks';
import { serviceNames } from '../../discovery-service/constants';
import { formatError } from '../../utils/utils';
import { useUsers } from './UsersProvider';
import { useWorkspaces } from './WorkspaceProvider';

type Permission =
  | 'canViewRunnables'
  | 'canEditRunnables'
  | 'canViewWorkflows'
  | 'canEditWorkflows'
  | 'canRunWorkflows'
  | 'canViewAccessControl';

export type Permissions = {
  [key in Permission]: boolean;
};

type RolesContextType = {
  allRoles?: Role[];
  bootstrapRoles?: Role[];
  orchestratorRoles?: Role[];
  roleBindings?: RoleBinding[];
  currentUserRoleBindings?: RoleBinding[];
  permissions: Permissions;
  loadingPermissions: boolean;
  createRoleBinding: (role: string, userId: string) => void;
  deleteRoleBinding: (roleBinding: RoleBinding) => void;
  createRoleBindings: (roles: string[], userId: string) => void;
  deleteRoleBindings: (roleBindings: RoleBinding[]) => void;
  addRoleBindingToUsers: (role: string, userIds: string[]) => void;
  loadingRoleBindings: boolean;
};

const RolesContext = React.createContext<RolesContextType | undefined>(undefined);

const RoleProvider = ({ children }: { children: React.ReactNode }) => {
  const authzService = useAuthzService(),
    { addToast } = useToast(),
    { ACTIVE_WORKSPACE_NAME } = useWorkspaces(),
    { CURRENT_USER_NAME } = useUsers(),
    [allRoles, setAllRoles] = React.useState<Role[] | undefined>(),
    [orchestratorRoles, setOrchestratorRoles] = React.useState<Role[] | undefined>(),
    [bootstrapRoles, setBootstrapRoles] = React.useState<Role[] | undefined>(),
    [roleBindings, setRoleBindings] = React.useState<RoleBinding[] | undefined>(),
    [currentUserRoleBindings, setCurrentUserRoleBindings] = React.useState<RoleBinding[] | undefined>(),
    [loadingPermissions, setLoadingPermissions] = React.useState(true),
    loadStateRef = React.useRef({
      fetchRoles: false,
      fetchRoleBindings: false,
      fetchRoleBindingsByUser: false,
    }),
    [permissions, setPermissions] = React.useState<Permissions>({
      canViewRunnables: false,
      canEditRunnables: false,
      canViewWorkflows: false,
      canEditWorkflows: false,
      canRunWorkflows: false,
      canViewAccessControl: false,
    }),
    [loading, setLoading] = React.useState(true),
    evaluateLoading = () => {
      if (
        !loadStateRef.current.fetchRoles &&
        !loadStateRef.current.fetchRoleBindings &&
        !loadStateRef.current.fetchRoleBindingsByUser
      ) {
        setLoading(false);
      }
    },
    fetchRoles = React.useCallback(async () => {
      loadStateRef.current.fetchRoles = true;
      setLoading(true);
      try {
        const data = await authzService.getRoles({});
        const roleItems: Role[] | undefined = data?.roles;
        if (data && !data?.roles) console.error('No roles found in the response.');
        setAllRoles(roleItems);
      } catch (err) {
        const message = `Failed to fetch roles: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setOrchestratorRoles(undefined);
        setAllRoles(undefined);
      } finally {
        loadStateRef.current.fetchRoles = false;
        evaluateLoading();
      }
    }, [authzService, addToast]),
    fetchRoleBindings = React.useCallback(async () => {
      loadStateRef.current.fetchRoleBindings = true;
      setLoading(true);
      try {
        const data = await authzService.getRoleBindings({
          filter: `resource='//workspaceserver/${ACTIVE_WORKSPACE_NAME}'`,
        });
        const roleBindingItems: RoleBinding[] | undefined = data?.roleBindings || [];
        if (data && !data?.roleBindings) console.error('No role bindings found in the response.');
        setRoleBindings(roleBindingItems);
      } catch (err) {
        const message = `Failed to fetch role bindings: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setRoleBindings(undefined);
      } finally {
        loadStateRef.current.fetchRoleBindings = false;
        evaluateLoading();
      }
    }, [authzService, addToast, ACTIVE_WORKSPACE_NAME]),
    fetchRoleBindingsByUser = React.useCallback(
      async (userId: string) => {
        loadStateRef.current.fetchRoleBindingsByUser = true;
        setLoading(true);
        try {
          const data = await authzService.getRoleBindings({
            filter: `subject='${userId}'`,
          });
          const roleBindingItems: RoleBinding[] | undefined = data?.roleBindings;
          if (data && !data?.roleBindings) console.error(`No role bindings for ${userId} found in the response.`);
          setCurrentUserRoleBindings(roleBindingItems);
        } catch (err) {
          const message = `Failed to fetch role bindings for user ${userId}: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          setCurrentUserRoleBindings(undefined);
        } finally {
          loadStateRef.current.fetchRoleBindingsByUser = false;
          evaluateLoading();
        }
      },
      [authzService, addToast]
    ),
    createRoleBinding = React.useCallback(
      async (role: string, userId: string) => {
        try {
          await authzService.createRoleBinding({
            roleBinding: {
              resource: `//workspaceserver/${ACTIVE_WORKSPACE_NAME}`,
              subject: userId,
              role,
            },
          });
          addToast({
            messageBarType: MessageBarType.success,
            message: `Role binding created successfully for ${userId}`,
          });
        } catch (err: any) {
          const message = `Failed to create role binding: ${err?.message || err}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        } finally {
          fetchRoleBindings();
        }
      },
      [authzService, addToast, ACTIVE_WORKSPACE_NAME, fetchRoleBindings]
    ),
    deleteRoleBinding = React.useCallback(
      async (roleBinding: RoleBinding) => {
        try {
          await authzService.deleteRoleBinding({
            name: roleBinding.name,
          });
          addToast({
            messageBarType: MessageBarType.success,
            message: `Role binding deleted successfully for ${roleBinding.subject}`,
          });
        } catch (err: any) {
          const message = `Failed to delete role binding: ${err?.message || err}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        } finally {
          fetchRoleBindings();
        }
      },
      [authzService, addToast, fetchRoleBindings]
    ),
    // TODO: Check whether Promise.all() is a better option.
    createRoleBindings = React.useCallback(
      async (roles: string[], userId: string) => {
        roles.forEach(async (role) => {
          await createRoleBinding(role, userId);
        });
        fetchRoleBindings();
      },
      [createRoleBinding, fetchRoleBindings]
    ),
    deleteRoleBindings = React.useCallback(
      async (roleBindings: RoleBinding[]) => {
        roleBindings.forEach(async (roleBinding) => {
          await deleteRoleBinding(roleBinding);
        });
        fetchRoleBindings();
      },
      [deleteRoleBinding, fetchRoleBindings]
    ),
    addRoleBindingToUsers = React.useCallback(
      async (role: string, userIds: string[]) => {
        userIds.forEach(async (userId) => {
          await createRoleBinding(role, userId);
        });
        fetchRoleBindings();
      },
      [createRoleBinding, fetchRoleBindings]
    );

  React.useEffect(() => {
    if (ACTIVE_WORKSPACE_NAME) {
      void fetchRoles();
      void fetchRoleBindings();
    }
  }, [ACTIVE_WORKSPACE_NAME]);

  React.useEffect(() => {
    if (CURRENT_USER_NAME) void fetchRoleBindingsByUser(CURRENT_USER_NAME);
  }, [CURRENT_USER_NAME]);

  React.useEffect(() => {
    setOrchestratorRoles(allRoles?.filter((role) => role.creator === serviceNames.orchestrator));
    setBootstrapRoles(allRoles?.filter((role) => role.creator === 'bootstrap'));
  }, [allRoles]);

  React.useEffect(() => {
    if (currentUserRoleBindings) {
      if (
        currentUserRoleBindings.some(
          (roleBinding) =>
            roleBinding.role === 'roles/workspace-owner' || roleBinding.role === 'roles/personal-workspace-owner'
        )
      ) {
        setPermissions({
          canViewRunnables: true,
          canEditRunnables: true,
          canViewWorkflows: true,
          canEditWorkflows: true,
          canRunWorkflows: true,
          canViewAccessControl: true,
        });
        setLoadingPermissions(false);
        return;
      }

      const permissions: Permissions = {
        canViewRunnables: currentUserRoleBindings.some(
          (roleBinding) =>
            roleBinding.role === 'roles/orchestrator-runnable-viewer' ||
            roleBinding.role === 'roles/orchestrator-runnable-editor' ||
            roleBinding.role === 'roles/orchestrator-runnable-owner'
        ),
        canEditRunnables: currentUserRoleBindings.some(
          (roleBinding) =>
            roleBinding.role === 'roles/orchestrator-runnable-editor' ||
            roleBinding.role === 'roles/orchestrator-runnable-owner'
        ),
        canViewWorkflows: currentUserRoleBindings.some(
          (roleBinding) =>
            roleBinding.role === 'roles/orchestrator-workflow-viewer' ||
            roleBinding.role === 'roles/orchestrator-workflow-editor' ||
            roleBinding.role === 'roles/orchestrator-workflow-runner' ||
            roleBinding.role === 'roles/orchestrator-workflow-owner'
        ),
        canEditWorkflows: currentUserRoleBindings.some(
          (roleBinding) =>
            roleBinding.role === 'roles/orchestrator-workflow-editor' ||
            roleBinding.role === 'roles/orchestrator-workflow-owner'
        ),
        canRunWorkflows: currentUserRoleBindings.some(
          (roleBinding) =>
            roleBinding.role === 'roles/orchestrator-workflow-runner' ||
            roleBinding.role === 'roles/orchestrator-workflow-owner'
        ),
        canViewAccessControl: false,
      };
      setPermissions(permissions);
      setLoadingPermissions(false);
    }
  }, [currentUserRoleBindings, setPermissions]);

  const value = {
    allRoles,
    bootstrapRoles,
    orchestratorRoles,
    roleBindings,
    currentUserRoleBindings,
    permissions,
    loadingPermissions,
    createRoleBinding,
    deleteRoleBinding,
    createRoleBindings,
    deleteRoleBindings,
    addRoleBindingToUsers,
    loadingRoleBindings: loading,
  };

  return <RolesContext.Provider value={value}>{children}</RolesContext.Provider>;
};

const useRoles = () => {
  const context = React.useContext(RolesContext);
  if (!context) {
    throw new Error('useRoles must be used within a RoleProvider');
  }
  return context;
};

export { RoleProvider, useRoles };
