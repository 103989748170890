import { EntityActionTypeMSWRestHTTPMethod } from './types';

export enum EntityActionType {
  Create = 'Create',
  Get = 'Get',
  List = 'List',
  Update = 'Update',
  Delete = 'Delete',
  Reorder = 'Reorder',
  Assign = 'Assign',
}

// entity types
export enum EntityType {
  KernelImage = 'KernelImage',
  KernelTemplate = 'KernelTemplate',
  KernelSpec = 'KernelSpec',
}

export enum EntityFieldType {
  Text = 'Text',
  Number = 'Number',
  Bytes = 'Bytes',
  Boolean = 'Boolean',
  SelectEnum = 'SelectEnum',
  StringArray = 'StringArray',
  ReadOnlyStringArray = 'ReadOnlyStringArray',
  ReadOnly = 'ReadOnly',
  Order = 'Order',
  KeyValuePair = 'KeyValuePair',
  Hidden = 'Hidden',
  Semver = 'Semver',
  LatestAndAliases = 'LatestAndAliases',
  DisplayOnDisplayAndId = 'DisplayOnDisplayAndId',
  IdOnDisplayAndId = 'IdOnDisplayAndId',
  ConstraintNumeric = 'ConstraintNumeric',
  ConstraintDuration = 'ConstraintDuration',
  Duration = 'Duration',
  Yaml = 'Yaml',
}

export const entityActionTypeHTTPMethod: EntityActionTypeMSWRestHTTPMethod = {
  [EntityActionType.Create]: 'post',
  [EntityActionType.Get]: 'get',
  [EntityActionType.List]: 'get',
  [EntityActionType.Update]: 'patch',
  [EntityActionType.Delete]: 'delete',
  [EntityActionType.Reorder]: 'post',
  [EntityActionType.Assign]: 'post',
};
