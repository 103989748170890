import { Link } from '@h2oai/ui-kit';

export const RowHeaderTitle = ({ title, onClick }: { title?: string; onClick?: () => void }) =>
  onClick ? (
    <Link
      onClick={onClick}
      styles={{
        root: {
          color: '#323130', // TODO: Find out how to theme this.
          textDecoration: 'none',
          '&:hover': { color: '#323130' },
        },
      }}
    >
      <h3 style={{ margin: 0 }}>{title}</h3>
    </Link>
  ) : (
    <h3 style={{ margin: 0 }}>{title}</h3>
  );
