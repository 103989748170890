import { DetailsList, IColumn, IStyle, MessageBarType } from '@fluentui/react';
import {
  IDetailsListProps,
  IH2OTheme,
  ListRow,
  Loader,
  detailsListStylesCard,
  useClassNames,
  useTheme,
  useToast,
} from '@h2oai/ui-kit';
import React from 'react';

import { FailedToLoadView } from '../../components/FailedToLoadView/FailedToLoadView';
import { NoItemView } from '../../components/NoItemView/NoItemView';
import { useOrchestratorService } from '../../orchestrator/hooks';
import { ClassNamesFromIStyles } from '../../utils/models';
import { useWorkspaces } from './WorkspaceProvider';

interface IWorkflowCanvasWidgetStyles {
  widgetContainer: IStyle;
  row: IStyle;
  description: IStyle;
  nameLabel: IStyle;
  widgetContentTitle: IStyle;
  centerItems: IStyle;
}

const workflowCanvasWidgetStyles = (theme: IH2OTheme): IWorkflowCanvasWidgetStyles => {
  return {
    widgetContainer: {
      backgroundColor: theme.semanticColors?.contentBackground,
      borderRadius: 4,
    },
    row: {
      backgroundColor: theme.semanticColors?.contentBackground,
    },
    description: {
      display: 'flex',
      $nest: {
        '& p': {
          margin: '0px 8px 0px 0px',
          fontSize: '0.9em',
        },
      },
    },
    nameLabel: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'auto',
      maxWidth: 200,
    },
    widgetContentTitle: {
      margin: 0,
    },
    centerItems: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      justifyContent: 'center',
    },
  };
};

type RowData = {
  name?: string;
  displayName?: string;
  maxExecutors?: number | string;
  activeExecutorsCount?: number;
  onClick: () => void;
};

const ActivePools = () => {
  const theme = useTheme(),
    classNames = useClassNames<IWorkflowCanvasWidgetStyles, ClassNamesFromIStyles<IWorkflowCanvasWidgetStyles>>(
      'workflowCanvasWidget',
      workflowCanvasWidgetStyles(theme)
    ),
    { addToast } = useToast(),
    orchestratorService = useOrchestratorService(),
    [activePoolItems, setActivePoolItems] = React.useState<RowData[]>(),
    { ACTIVE_WORKSPACE_NAME } = useWorkspaces(),
    [loading, setLoading] = React.useState(true),
    fetchActivePools = React.useCallback(async () => {
      setLoading(true);
      try {
        const data = await orchestratorService.getDashboardExecutorPoolView({
          parent: ACTIVE_WORKSPACE_NAME || '',
        });
        if (data && !data?.dashboardExecutorPoolViews) {
          console.error('No executor pool views found in the response.');
        }
        const items: RowData[] =
          data.dashboardExecutorPoolViews?.map((e) => ({
            name: e?.name || '',
            displayName: e?.displayName || '',
            maxExecutors: e?.maxExecutors || 'Unlimited',
            activeExecutorsCount: e?.activeExecutorsCount || 0,
            onClick: () => {
              // TODO: Implement once the executor pool status page is ready.
            },
          })) ?? [];
        setActivePoolItems(items);
      } catch (error) {
        const message = `Failed to fetch executor pool view: ${error}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setActivePoolItems(undefined);
      } finally {
        setLoading(false);
      }
    }, [orchestratorService, addToast, ACTIVE_WORKSPACE_NAME, setActivePoolItems]),
    rowColumns: IColumn[] = [
      {
        key: 'widgetItem',
        name: 'Widget Item',
        fieldName: 'displayName',
        minWidth: 250,
        data: {
          headerFieldName: 'displayName',
          listCellProps: {
            emptyMessage: '',

            iconProps: {
              iconName: 'Devices2',
            },
            onRenderHeader: ({ displayName }: RowData) => (
              <h4 className={classNames.widgetContentTitle}>{displayName}</h4>
            ),
            onRenderText: ({ maxExecutors, activeExecutorsCount }: RowData) => (
              <div className={classNames.description}>
                <div>
                  <p>
                    <b>Max Executors: </b>
                    {maxExecutors}
                    <br />
                    <b>Active Executors: </b>
                    {activeExecutorsCount}
                  </p>
                </div>
              </div>
            ),
          },
        },
      },
    ],
    listProps: Partial<IDetailsListProps> = {
      focusZoneProps: { style: { width: '100%' } },
      isHeaderVisible: false,
      cellStyleProps: {
        cellExtraRightPadding: 0,
        cellLeftPadding: 0,
        cellRightPadding: 0,
      },
      onRenderRow: (props?: { item: { [key: string]: any }; columns?: IColumn[] }) => {
        if (!props) return null;
        const { item, columns = [] } = props;
        return (
          <ListRow
            data={item}
            columns={columns}
            styles={{
              row: {
                '&:hover': {
                  background: theme.semanticColors?.contentBackground,
                  '.h2o-ListCell-text': {
                    color: theme.semanticColors?.textSecondary,
                  },
                },
              },
            }}
          />
        );
      },
    };

  React.useEffect(() => {
    if (ACTIVE_WORKSPACE_NAME) {
      void fetchActivePools();
      // TODO: Cleanup running requests on unmount.
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ACTIVE_WORKSPACE_NAME, fetchActivePools]);

  return (
    <>
      <h3>Active executor pools</h3>
      <div className={classNames.widgetContainer}>
        {loading ? (
          <div className={classNames.centerItems}>
            <Loader />
          </div>
        ) : activePoolItems ? (
          activePoolItems.length ? (
            <DetailsList
              {...listProps}
              columns={rowColumns}
              items={activePoolItems}
              // TODO: Fix minWidth. Add elipsis to the name.
              styles={detailsListStylesCard(theme)}
            />
          ) : (
            NoItemView({
              title: 'No active executor pools',
              description: 'There are no active executor pools right now.',
              shadowed: false,
            })
          )
        ) : (
          FailedToLoadView({
            title: 'Failed to load active pools',
            description: 'Please try again later. If the problem persists, contact our support.',
            actionTitle: 'Retry',
            onActionClick: fetchActivePools,
            actionIcon: 'Refresh',
          })
        )}
      </div>
    </>
  );
};

export default ActivePools;
