import { concatStyleSets } from '@fluentui/react';
import {
  Button,
  type IButtonProps,
  IconName,
  buttonStylesSplit,
  buttonStylesSplitSecondary,
  buttonStylesSplitSmall as uiKitButtonStylesSplitSmall,
  useTheme,
} from '@h2oai/ui-kit';

import { Entity } from '../Entity/types';

export interface EntityModelItemActionButtonProps<EntityModel> {
  model: EntityModel;
  entity: Entity<any>;
  onEdit?: (model: EntityModel) => void;
  onView?: (model: EntityModel) => void;
  onDelete?: (model: EntityModel) => void;
  onSetLatest?: (model: EntityModel) => void;
}

const buttonStylesSplitSmall = concatStyleSets(uiKitButtonStylesSplitSmall, { root: { width: 60 } });

export const EntityModelItemActionButton = <EntityModel,>(props: EntityModelItemActionButtonProps<EntityModel>) => {
  const { entity, model, onEdit, onView, onDelete, onSetLatest } = props;
  const { palette, semanticColors } = useTheme();
  const buttonProps: IButtonProps = { split: true, menuIconName: IconName.ChevronDown };
  const aliasesFieldName = 'aliases';
  const aliasesField = model[aliasesFieldName] as string[] | undefined;
  const canBeLatest = aliasesField && !aliasesField.includes('latest');
  const menuItems = [
    ...(canBeLatest
      ? [
          {
            key: 'setLatest',
            text: 'Set Latest',
            onClick: () => onSetLatest!(model),
            style: { color: semanticColors?.textPrimary, maxHeight: 32 },
          },
        ]
      : []),
    {
      key: 'delete',
      text: 'Delete',
      onClick: () => onDelete!(model),
      style: { color: palette?.red500, maxHeight: 32 },
      disabled: !entity.actions.Delete,
    },
  ];
  const primaryAction = entity.actions.Update ? onEdit : onView;
  const primaryText = entity.actions.Update ? 'Edit' : 'View';
  const primaryDisabled = primaryText === 'Edit' && !entity.actions.Update;

  return (
    <>
      <Button
        {...buttonProps}
        menuItems={menuItems}
        styles={[buttonStylesSplit, buttonStylesSplitSecondary, buttonStylesSplitSmall]}
        onClick={() => primaryAction!(model)}
        primaryDisabled={primaryDisabled}
        text={primaryText}
      />
    </>
  );
};
