import { IStyle, MessageBarType, mergeStyles } from '@fluentui/react';
import {
  Button,
  IH2OTheme,
  Loader,
  TooltipHost,
  buttonStylesStealth,
  calloutContentStylesTooltip,
  loaderStylesSpinnerDefault,
  loaderStylesSpinnerTag,
  useClassNames,
  useTheme,
  useToast,
} from '@h2oai/ui-kit';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { WorkflowExecution_State } from '../../orchestrator/gen/ai/h2o/orchestrator/v1/workflow_execution_pb';
import { useOrchestratorService } from '../../orchestrator/hooks';
import { ClassNamesFromIStyles } from '../../utils/models';
import { formatError } from '../../utils/utils';
import { extractWorkflowName } from './Executions';
import { WorkflowFixed } from './WorkflowTabCanvas';
import { Tag, WorkflowExecutionItem, calculateDuration, getStateProps } from './WorkflowTabExecutions';
import { useWorkspaces } from './WorkspaceProvider';

interface IWorkflowRunChartStyles {
  header: IStyle;
  loader: IStyle;
  buttonActive: IStyle;
  chartWidgetContainer: IStyle;
  chartRow: IStyle;
  tooltipContent: IStyle;
  bar: IStyle;
  centerItems: IStyle;
  loaderWrapper: IStyle;
}

const workflowRunChartStyles = (theme: IH2OTheme): IWorkflowRunChartStyles => {
    return {
      header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      loader: { position: 'relative', height: 22 },
      buttonActive: {
        backgroundColor: theme.palette?.gray300,
        borderRadius: 6,
      },
      chartWidgetContainer: {
        height: 200,
        padding: 20,
        backgroundColor: theme.semanticColors?.contentBackground,
        borderRadius: 4,
      },
      chartRow: {
        display: 'flex',
        alignItems: 'flex-end',
        backgroundColor: theme.semanticColors?.contentBackground,
        width: '100%',
        height: '100%',
        overflowY: 'hidden',
        overflowX: 'scroll',
        textWrap: 'nowrap',
        // TODO: Handle max width properly.
        maxWidth: '60vw',
        // Hide scrollbar for Chrome, Safari and Opera.
        '::-webkit-scrollbar': {
          display: 'none',
        },
        // Hide scrollbar for IE, Edge and Firefox.
        '-ms-overflow-style': 'none', // IE and Edge
        'scrollbar-width': 'none', // Firefox
      },
      tooltipContent: {
        padding: 8,
      },
      bar: {
        width: 16,
        height: 150,
        backgroundColor: '#A4DBA6',
        borderRadius: 4,
        marginRight: 4,
        marginLeft: 4,
        marginTop: 10,
        transition: 'background-color 0.3s',
        cursor: 'pointer',
        ':hover': {
          backgroundColor: '#B2FAA1',
        },
      },
      centerItems: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      },
      loaderWrapper: {
        height: 200,
      },
    };
  },
  loaderStyles = [
    loaderStylesSpinnerDefault,
    loaderStylesSpinnerTag,
    {
      root: {
        backgroundColor: 'transparent',
        justifyContent: 'flex-start',
      },
      // TODO: Use theme.
      label: { color: 'var(--h2o-primary200, #FFECB3)' },
      circle: {
        borderColor:
          // TODO: Use theme.
          'var(--h2o-primary700, #BF8F00) var(--h2o-primary200, #FFECB3) var(--h2o-primary200, #FFECB3);',
      },
    },
  ],
  getDateFilter = (period?: 'last-8-hours' | 'last-24-hours' | 'last-week') => {
    switch (period) {
      case 'last-8-hours':
        return `start_time >= ${new Date(Date.now() - 8 * 60 * 60 * 1000).toISOString()}`;
      case 'last-24-hours':
        return `start_time >= ${new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString()}`;
      case 'last-week':
        return `start_time >= ${new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString()}`;
      default:
        return undefined;
    }
  },
  getDurationInSeconds = (start?: string, end?: string) => {
    if (!start) return 0;
    const startTime = new Date(start).getTime();
    const endTime = end ? new Date(end).getTime() : Date.now();
    return Math.floor((endTime - startTime) / 1000);
  };

const WorkflowRunChart = () => {
  const theme = useTheme(),
    classNames = useClassNames<IWorkflowRunChartStyles, ClassNamesFromIStyles<IWorkflowRunChartStyles>>(
      'workflowRunChart',
      workflowRunChartStyles(theme)
    ),
    { addToast } = useToast(),
    orchestratorService = useOrchestratorService(),
    { ACTIVE_WORKSPACE_NAME } = useWorkspaces(),
    history = useHistory(),
    [filterBy, setFilterBy] = React.useState<'last-8-hours' | 'last-24-hours' | 'last-week'>('last-24-hours'),
    [executionsItems, setExecutionsItems] = React.useState<WorkflowExecutionItem[]>(),
    [workflows, setWorkflows] = React.useState<WorkflowFixed[]>(),
    [durationInterval, setDurationInterval] = React.useState<{ min: number; max: number }>({ min: 0, max: 100 }),
    [loading, setLoading] = React.useState(true),
    loadStateRef = React.useRef({
      fetchExecutions: false,
      fetchWorkflows: false,
    }),
    evaluateLoading = () => {
      if (!loadStateRef.current.fetchExecutions && !loadStateRef.current.fetchWorkflows) {
        setLoading(false);
      }
    },
    fetchExecutions = React.useCallback(async () => {
      loadStateRef.current.fetchExecutions = true;
      setLoading(true);
      try {
        const data = await orchestratorService.getWorkflowExecutions({
          parent: ACTIVE_WORKSPACE_NAME || '',
          filter: getDateFilter(filterBy),
          orderBy: 'start_time desc',
        });
        const items = data?.workflowExecutions
          ? data.workflowExecutions.map((item) => ({
              ...item,
              workflowDisplayName:
                workflows?.find((w) => w.name === extractWorkflowName(item.name))?.displayName ||
                extractWorkflowName(item.name) ||
                item.name,
              startTimeLocal: item.startTime ? new Date(item.startTime).toLocaleString() : undefined,
              endTimeLocal: item.endTime ? new Date(item.endTime).toLocaleString() : undefined,
              runBy: item.initiator
                ? `${item.initiator.includes('/workflows') ? 'Triggered' : 'Manually run'} by ${
                    item.initiatorDisplayName || item.initiator
                  }`
                : '(Unknown)',
              duration: calculateDuration(item.startTime, item.endTime),
              onView: () => history.push(`/orchestrator/${item.name}`),
            }))
          : undefined;
        setExecutionsItems(items);
      } catch (err) {
        const message = `Failed to fetch workflow executions: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setExecutionsItems(undefined);
      } finally {
        loadStateRef.current.fetchExecutions = false;
        evaluateLoading();
      }
    }, [ACTIVE_WORKSPACE_NAME, addToast, history, orchestratorService, workflows, filterBy]),
    fetchWorkflows = React.useCallback(async () => {
      loadStateRef.current.fetchWorkflows = true;
      setLoading(true);
      try {
        const data = await orchestratorService.getWorkflows({ parent: ACTIVE_WORKSPACE_NAME || '' });
        setWorkflows(data?.workflows);
        setExecutionsItems((items) =>
          items?.map((item) => ({
            ...item,
            workflowDisplayName:
              data?.workflows?.find((w) => w.name === extractWorkflowName(item.name))?.displayName ||
              extractWorkflowName(item.name) ||
              '(Unknown)',
          }))
        );
      } catch (err) {
        const message = `Failed to fetch workflows: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setWorkflows(undefined);
      } finally {
        loadStateRef.current.fetchWorkflows = false;
        evaluateLoading();
      }
    }, [ACTIVE_WORKSPACE_NAME, addToast, orchestratorService]);

  React.useEffect(() => {
    if (ACTIVE_WORKSPACE_NAME) {
      void fetchWorkflows();
      void fetchExecutions();
      // TODO: Cleanup running requests on unmount.
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ACTIVE_WORKSPACE_NAME, filterBy]);

  React.useEffect(() => {
    setExecutionsItems((items) =>
      items?.map((item) => ({
        ...item,
        workflowDisplayName:
          workflows?.find((w) => w.name === extractWorkflowName(item.name))?.displayName ||
          extractWorkflowName(item.name) ||
          item.name,
      }))
    );
  }, [workflows]);

  React.useEffect(() => {
    if (executionsItems?.length) {
      const durations = executionsItems.map((item) => getDurationInSeconds(item.startTime, item.endTime));
      setDurationInterval({
        min: Math.min(...durations),
        max: Math.max(...durations),
      });
    }
  }, [executionsItems]);

  return (
    <div>
      <div className={classNames.header}>
        <h3>Workflow runs</h3>
        {!loading ? (
          <div>
            <Button
              styles={buttonStylesStealth}
              text="8h"
              onClick={() => setFilterBy('last-8-hours')}
              className={filterBy === 'last-8-hours' ? classNames.buttonActive : undefined}
            />
            <Button
              styles={buttonStylesStealth}
              text="24h"
              onClick={() => setFilterBy('last-24-hours')}
              className={filterBy === 'last-24-hours' ? classNames.buttonActive : undefined}
            />
            <Button
              styles={buttonStylesStealth}
              text="1w"
              onClick={() => setFilterBy('last-week')}
              className={filterBy === 'last-week' ? classNames.buttonActive : undefined}
            />
          </div>
        ) : null}
      </div>
      {loading ? (
        <div className={mergeStyles(classNames.centerItems, classNames.loaderWrapper)}>
          <Loader />
        </div>
      ) : (
        <div className={classNames.chartWidgetContainer}>
          <div className={classNames.chartRow}>
            {executionsItems?.length ? (
              executionsItems?.map((item, key) => (
                <TooltipHost
                  key={`bar-item-${key}`}
                  calloutContentStyles={calloutContentStylesTooltip}
                  content={
                    <>
                      <div key={item.name} className={classNames.tooltipContent}>
                        <h2>{item.workflowDisplayName}</h2>
                        <p>{item.runBy}</p>
                        <p>
                          <b>Started at</b> {item.startTimeLocal}
                        </p>
                        {item?.endTime ? (
                          <p>
                            <b>Ended at</b> {item.endTimeLocal}
                          </p>
                        ) : null}
                        <p>
                          <b>Duration:</b> {item.duration}
                        </p>
                        <>
                          {item.state === WorkflowExecution_State.RUNNING ? (
                            <Loader label="Running" styles={loaderStyles} className={classNames.loader} />
                          ) : (
                            <Tag title={getStateProps(item.state).name} color={getStateProps(item.state).color} />
                          )}
                        </>
                      </div>
                    </>
                  }
                >
                  <div
                    className={classNames.bar}
                    onClick={item.onView}
                    style={{
                      backgroundColor: getStateProps(item.state).color,
                      /* Calculate height based on duration. Heights are distributed between 50px and 150px. */
                      height: item?.duration
                        ? Math.max(
                            50,
                            Math.min(
                              150,
                              50 +
                                (getDurationInSeconds(item.startTime, item.endTime) - durationInterval.min) /
                                  ((durationInterval.max - durationInterval.min) / 100)
                            )
                          )
                        : 50,
                    }}
                  />
                </TooltipHost>
              ))
            ) : (
              <div className={classNames.centerItems}>No runs to show in selected period.</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default WorkflowRunChart;
