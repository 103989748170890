import { Project } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/project_pb';
import { IButtonStyles, INavLink, IStyle, KeyCodes, Stack } from '@fluentui/react';
import { IContextualMenuItem, IContextualMenuListProps } from '@fluentui/react/lib/ContextualMenu';
import { Icon } from '@fluentui/react/lib/Icon';
import { ISearchBoxStyles, SearchBox } from '@fluentui/react/lib/SearchBox';
import { IRenderFunction } from '@fluentui/react/lib/Utilities';
import {
  Button,
  IH2OTheme,
  IStyleFunctionOrObject,
  Link,
  Nav,
  buttonStylesIconCarousel,
  mediaDesktop,
  mediaNoDesktop,
  useClassNames,
  useTheme,
} from '@h2oai/ui-kit';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { stackStylesPage } from '../../themes/themes';
import { useLeftPanel } from '../../utils/hooks';
import { ClassNamesFromIStyles } from '../../utils/models';
import { ENDPOINTS } from './apiEndpoints';
import { useProjects } from './ProjectProvider';

interface INavigationStyles {
  projectWidget: IStyle;
  widgetTitle: IStyle;
  contextualMenu: IStyle;
  nav: IStyle;
}

interface INavigationWrapperStyles {
  container: IStyle;
  stack: IStyle;
  noPermissions: IStyle;
}

const navigationStyles = (theme: IH2OTheme): Partial<INavigationStyles> => ({
    projectWidget: {
      backgroundColor: theme.semanticColors?.bodyBackground,
      padding: '20px 10px',
      borderRadius: 8,
      position: 'absolute',
      bottom: 90,
      maxWidth: 246,
    },
    widgetTitle: {
      color: theme.semanticColors?.messageBarTitleText,
      marginTop: 0,
    },
    contextualMenu: { width: 226 },
    nav: {
      marginTop: 14,
    },
  }),
  navigationWrapperStyles = (): Partial<INavigationWrapperStyles> => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    stack: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 0,
    },
    noPermissions: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
      whiteSpace: 'pre-line',
      textAlign: 'center',
    },
  }),
  expandButtonStyle = (theme: IH2OTheme): React.CSSProperties => ({
    zIndex: 10,
    position: 'fixed',
    backgroundColor: theme.semanticColors?.contentBackground,
    outlineWidth: 1,
    outlineStyle: 'solid',
    outlineColor: theme.semanticColors?.inputBorder,
    left: 250,
    transform: 'rotate(180deg)',
    border: 0,
  }),
  filteredItemsStyle: React.CSSProperties = {
    width: '100%',
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchBoxStyles: ISearchBoxStyles = {
    root: { margin: '8px' },
  },
  accessControlStyles: IStyleFunctionOrObject<IButtonStyles> | IStyleFunctionOrObject<IButtonStyles>[] | undefined = {
    label: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 18,
    },
    root: {
      height: 20,
      minHeight: 20,
      padding: '0 8px',
      border: 'none',
    },
  },
  getRoutes = (projectName: string) => {
    return [
      {
        key: 'home',
        name: 'Home',
        url: `/mlops/projects/${projectName}`,
        icon: 'Home',
      },
      {
        key: 'models',
        name: 'Models',
        url: `/mlops/projects/${projectName}${ENDPOINTS.MODELS}`,
        // TODO: Replace with 'MachineLearning' when icons are updated.
        icon: 'HomeGroup',
      },
      {
        key: 'deployments',
        name: 'Real-time deployments',
        url: `/mlops/projects/${projectName}${ENDPOINTS.DEPLOYMENTS}`,
        icon: 'PublishContent',
      },
      {
        key: 'batchScoring',
        name: 'Batch scoring jobs',
        url: `/mlops/projects/${projectName}${ENDPOINTS.BATCH_SCORING}`,
        icon: 'AssessmentGroup',
      },
    ];
  };

const Navigation = ({
  projects,
  activateProject,
  activeProject,
  onToggleLeftPanel,
}: {
  activeProject?: Project;
  projects?: Project[];
  activateProject: (name: string) => void;
  onToggleLeftPanel: () => void;
}) => {
  const [selectedKey, setSelectedKey] = React.useState<string>(),
    history = useHistory(),
    theme = useTheme(),
    classNames = useClassNames<INavigationStyles, ClassNamesFromIStyles<INavigationStyles>>(
      'navigationPane',
      navigationStyles(theme)
    ),
    menuItems: IContextualMenuItem[] = React.useMemo(
      () =>
        projects?.map((project) => ({
          key: project.id || '',
          text: project.displayName,
          iconProps: project.id === activeProject?.id ? { iconName: 'BoxCheckmarkSolid' } : undefined,
          onClick: () => activateProject(project.id || ''),
        })) || [],
      [activateProject, projects, activeProject]
    ),
    [items, setItems] = React.useState(menuItems),
    linkClickHandler = (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
      const { key, url } = item || {};
      if (!ev || !key || !url) return;
      ev.preventDefault(); // Prevents page reload.
      setSelectedKey(key);
      history.push(url);
    },
    [sideNavRoutes, setSideNavRoutes] = React.useState<INavLink[]>([]),
    onAbort = React.useCallback(() => {
      setItems(menuItems);
    }, [menuItems]),
    onChange = React.useCallback(
      (_ev?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
        if (newValue === undefined) return;
        const filteredItems = menuItems.filter(
          (item) => item.text && item.text.toLowerCase().indexOf(newValue.toLowerCase()) !== -1
        );

        if (!filteredItems || !filteredItems.length) {
          filteredItems.push({
            key: 'no_results',
            onRender: (_item, _dismissMenu) => (
              <div key="no_results" style={filteredItemsStyle}>
                <Icon iconName="SearchIssue" title="No projects found" />
                <span>No project found</span>
              </div>
            ),
          });
        }

        setItems(filteredItems);
      },
      [menuItems]
    ),
    onKeyDown = React.useCallback((e) => {
      /* Key Up, but we are not at the beginning of the text: stop event propagation to prevent ContextualMenu to focus */
      if (e.target.selectionStart > 0 && e.which === KeyCodes.up) {
        e.stopPropagation();
      }
      /* Key Down, but we are not at the end of the text: stop event propagation to prevent ContextualMenu to focus */
      if (e.target.selectionStart !== e.target.value.length && e.which === KeyCodes.down) {
        e.stopPropagation();
      }
    }, []),
    onDismiss = React.useCallback(() => {
      setItems(menuItems);
    }, [menuItems]),
    renderMenuList = React.useCallback(
      (menuListProps?: IContextualMenuListProps, defaultRender?: IRenderFunction<IContextualMenuListProps>) => {
        return (
          <div className={classNames.contextualMenu}>
            <div style={{ borderBottom: '1px solid #ccc' }}>
              <SearchBox
                ariaLabel="Filter projects by text"
                placeholder="Filter projects"
                onAbort={onAbort}
                onChange={onChange}
                onKeyDown={onKeyDown}
                styles={searchBoxStyles}
              />
            </div>
            {defaultRender?.(menuListProps)}
            <br />
            <Link
              style={{ marginLeft: 12 }}
              onClick={() => {
                history.push('/mlops/projects');
                setSelectedKey('');
              }}
            >
              Manage projects
            </Link>
          </div>
        );
      },
      [onAbort, onChange, onKeyDown]
    ),
    menuProps = React.useMemo(
      () => ({
        onRenderMenuList: renderMenuList,
        shouldFocusOnMount: true,
        items,
        focusZoneProps: {
          /* Allow up and down arrows to move focus out of the SearchBox */
          shouldInputLoseFocusOnArrowKey: () => true,
        },
        onDismiss,
      }),
      [items, renderMenuList, onDismiss]
    );

  React.useEffect(() => {
    const newItems: IContextualMenuItem[] =
      projects?.map((project) => ({
        key: project.id || '',
        text: project.displayName,
        iconProps: project.id === activeProject?.id ? { iconName: 'BoxCheckmarkSolid' } : undefined,
        onClick: () => activateProject(project.id || ''),
      })) || [];
    setItems(newItems);
  }, [projects, activeProject, activateProject]);

  React.useEffect(() => {
    const afterProjects = history.location.pathname?.split('projects/')?.[1],
      item = afterProjects?.split('/')?.[1],
      keyMap = {
        models: 'models',
        deployments: 'deployments',
        monitoring: 'monitoring',
        default: afterProjects?.split('/')?.length >= 1 ? 'home' : '',
      },
      newKey = keyMap[item] ?? keyMap.default;

    if (selectedKey !== newKey) setSelectedKey(newKey);
  }, [history.location.pathname]);

  React.useEffect(() => {
    if (activeProject?.id) setSideNavRoutes(getRoutes(activeProject?.id));
  }, [activeProject?.id]);

  return (
    <>
      <Button
        iconName="ChevronRightSmall"
        id="left-nav-panel-expand-button"
        onClick={onToggleLeftPanel}
        style={expandButtonStyle(theme)}
        styles={[
          buttonStylesIconCarousel,
          {
            icon: {
              transition: 'transform 0.3s linear 0s !important',
            },
          },
        ]}
        title="Collapse/Expand"
      />
      <Nav
        groups={[{ links: sideNavRoutes }]}
        onLinkClick={linkClickHandler}
        selectedKey={selectedKey}
        className={classNames.nav}
      />
      <div className={classNames.projectWidget} id="left-nav-panel-workspace-widget">
        <h4 className={classNames.widgetTitle} id="left-nav-panel-workspace-widget-title">
          Active project
        </h4>
        <Button
          title="Manage projects"
          iconName="FolderHorizontal"
          text={activeProject?.displayName || 'No project selected'}
          menuIconName="ChevronDown"
          menuProps={menuProps}
          styles={{ root: { width: '226px' } }}
        />
        <Button
          title="Project settings"
          text="Project settings"
          onClick={() => {
            history.push(`/mlops/projects/${activeProject?.id}${ENDPOINTS.PROJECT_SETTINGS}`);
          }}
          iconName="Settings"
          styles={accessControlStyles}
          buttonContainerStyles={{ root: { marginTop: 12, textAlign: 'center' } }}
        />
      </div>
    </>
  );
};

const NavigationWrapper: React.FC = ({ children }) => {
  const setLeftPanelProps = useLeftPanel(),
    { ACTIVE_PROJECT_ID, projects, activateProject, activeProject } = useProjects(),
    theme = useTheme(),
    classNames = useClassNames<INavigationWrapperStyles, ClassNamesFromIStyles<INavigationWrapperStyles>>(
      'navigationWrapper',
      navigationWrapperStyles
    ),
    timeoutRef = React.useRef<number>(),
    // TODO: Handle loading.
    [loading] = React.useState(true),
    [, setShowLoader] = React.useState<boolean>(false),
    [isLeftPanelOpen, setIsLeftPanelOpen] = React.useState(true);

  React.useEffect(() => {
    if (!ACTIVE_PROJECT_ID) return;
    setLeftPanelProps({
      className: isLeftPanelOpen ? 'left-nav-panel' : 'left-nav-panel-force-closed',
      content: (
        <Navigation
          activeProject={activeProject}
          projects={projects}
          activateProject={activateProject}
          onToggleLeftPanel={() => setIsLeftPanelOpen((isOpen) => !isOpen)}
        />
      ),
      styles: {
        root: {
          padding: 12,
          backgroundColor: theme.semanticColors?.contentBackground,
          boxShadow: '6px 0px 10px -6px var(--h2o-gray400_a_16, #1C1D2129)',
          ...mediaDesktop({ height: 'calc(100% - 96px)' }), // Substract desktop header height.
          ...mediaNoDesktop({
            height: 'calc(100% - 60px)', // Substract mobile header height.
          }),
        },
      },
    });
    return () => setLeftPanelProps({ content: undefined });
  }, [setLeftPanelProps, ACTIVE_PROJECT_ID, activateProject, projects, activeProject, isLeftPanelOpen]);

  React.useEffect(() => {
    if (loading) {
      timeoutRef.current = window.setTimeout(() => setShowLoader(true), 1000);
    } else {
      setShowLoader(false);
      window.clearTimeout(timeoutRef.current);
    }
    return () => window.clearTimeout(timeoutRef.current);
  }, [loading]);

  return (
    <div className={classNames.container}>
      <Stack styles={stackStylesPage} className={classNames.stack}>
        {children}
      </Stack>
    </div>
  );
};

export default NavigationWrapper;
