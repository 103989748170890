import { Label, Stack } from '@fluentui/react';

import { defaultEntityLabelColumnWidth } from './DefaultEntityFormRowStyles';

type LabelAndDescriptionProps = {
  label: string;
  description?: string;
  light?: boolean;
  required?: boolean;
};

export const LabelAndDescription = (props: LabelAndDescriptionProps) => {
  const { label, description, light = false, required = false } = props;

  return (
    <Stack
      styles={
        light
          ? undefined
          : { root: { minWidth: defaultEntityLabelColumnWidth, maxWidth: defaultEntityLabelColumnWidth } }
      }
    >
      <Label styles={{ root: { fontWeight: light ? 400 : 700 } }} required={required}>
        {label}
      </Label>
      {description && <p>{description}</p>}
    </Stack>
  );
};
