import { Stack, Toggle } from '@fluentui/react';
import { useCallback, useEffect, useReducer, useRef } from 'react';

import { ConstraintNumeric } from '../../../../aiem/gen/ai/h2o/engine/v1/constraint_pb';
import SpinnerWithTooltip from '../../../SpinnerWithTooltip/SpinnerWithTooltip';
import { ConstraintType, constraintTypeMapAttributes } from '../../constants';
import type { EntityFieldInputProps } from '../BasicEntityModelComponents/types';
import { defaultEntityFormRowStyles } from '../DefaultEntityFormRowStyles';
import { LabelAndDescription } from '../LabelAndDescription';
import type {
  ConstraintSetActions,
  ConstraintSetReducerFunction,
  ConstraintSetState,
  ConstraintSetSubFieldContainerProps,
} from './types';

export enum ConstraintSetAction {
  MIN = 'min',
  INFINITE = 'infinite',
  MAX = 'max',
  DEFAULT_VALUE = 'defaultValue',
}

const ConstraintSetSubFieldContainer = ({ children }: ConstraintSetSubFieldContainerProps) => (
  <Stack verticalAlign="center" horizontalAlign="center" styles={{ root: { minWidth: 50 } }}>
    {children}
  </Stack>
);

const constraintSetReducer: ConstraintSetReducerFunction = (
  state: ConstraintSetState,
  action: ConstraintSetActions
): ConstraintSetState => {
  const newState = { ...state };
  switch (action.type) {
    case ConstraintSetAction.MIN:
      newState.min = action.value;
      break;
    case ConstraintSetAction.MAX:
      newState.max = action.value;
      break;
    case ConstraintSetAction.DEFAULT_VALUE:
      newState.defaultValue = action.value;
      break;
    case ConstraintSetAction.INFINITE:
      newState.infinite = action.value;
      break;
  }
  return newState;
};

const ConstraintSetModelField = <EntityModel,>(props: EntityFieldInputProps<EntityModel>) => {
  const { field, model, onChange: onChangeModel } = props;
  const { constraintType = ConstraintType.CPU, label, name, description, referenceName } = field;
  const attributes = constraintTypeMapAttributes[constraintType] || {};
  const { max: maxMax, min: minMin, suffix, ToView, FromView } = attributes;
  const constraint: ConstraintNumeric = (model[referenceName || name] as ConstraintNumeric) || {
    min: '0',
    max: '0',
    default: '0',
  };

  const convertToView = useCallback(
    (value): number => {
      const newValue = Number(ToView ? ToView(value) : value);
      if (Number.isNaN(newValue)) {
        return 0;
      } else {
        return newValue;
      }
    },
    [ToView]
  );

  const firstRender = useRef<boolean>(true);
  const originalMax = useRef<string | number | null | undefined>(ToView ? ToView(constraint.max) : constraint.max);
  const [constraintSetState, constraintSetDispatch] = useReducer<ConstraintSetReducerFunction>(constraintSetReducer, {
    min: convertToView(constraint.min),
    max: convertToView(constraint.max),
    defaultValue: convertToView(constraint.default),
    infinite: !Boolean(constraint.max),
  });

  const updateMin = (value: number) => {
    if (value === undefined) {
      return;
    }
    constraintSetDispatch({ type: ConstraintSetAction.MIN, value });
    // if the default is below the min now, we adjust that too:
    if (value > constraintSetState.defaultValue) {
      constraintSetDispatch({ type: ConstraintSetAction.DEFAULT_VALUE, value });
    }
    // if the max is below the min now, we adjust that too
    if (value > constraintSetState.max && !constraintSetState.infinite) {
      constraintSetDispatch({ type: ConstraintSetAction.MAX, value });
    }
  };
  const updateMax = (value: number) => {
    if (value === undefined) {
      return;
    }
    constraintSetDispatch({ type: ConstraintSetAction.MAX, value });
    originalMax.current = value;
    // if the max is below the default, adjust the default:
    if (value < constraintSetState.defaultValue) {
      constraintSetDispatch({ type: ConstraintSetAction.DEFAULT_VALUE, value });
    }
    // if the max is below the min now, we adjust that too
    if (value < constraintSetState.min) {
      constraintSetDispatch({ type: ConstraintSetAction.MIN, value });
    }
  };
  const updateDefaultValue = (value: number) => {
    constraintSetDispatch({ type: ConstraintSetAction.DEFAULT_VALUE, value });
  };
  const updateInfinite = (value: boolean) => {
    if (!value) {
      const newValue = originalMax.current
        ? Math.max(Number(originalMax.current), constraintSetState.min, constraintSetState.defaultValue)
        : constraintSetState.defaultValue;
      constraintSetDispatch({ type: ConstraintSetAction.MAX, value: newValue });
      if (originalMax.current === undefined) {
        constraintSetDispatch({ type: ConstraintSetAction.DEFAULT_VALUE, value: newValue });
      }
    }
    if (value) {
      constraintSetDispatch({ type: ConstraintSetAction.MAX, value: null });
    }
    constraintSetDispatch({ type: ConstraintSetAction.INFINITE, value });
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (!onChangeModel) {
      return;
    }
    const newConstraintSet = {
      min: FromView ? FromView(constraintSetState.min) : constraintSetState.min,
      default: FromView ? FromView(constraintSetState.defaultValue) : constraintSetState.defaultValue,
    };
    if (!constraintSetState.infinite) {
      newConstraintSet['max'] = FromView ? FromView(constraintSetState.max) : constraintSetState.max;
    }
    onChangeModel(referenceName || name, newConstraintSet);
  }, [constraintSetState]);

  return (
    <Stack styles={{ root: defaultEntityFormRowStyles }}>
      <LabelAndDescription light={true} label={label} description={description} required={field.required} />

      <Stack horizontal horizontalAlign="space-between">
        <ConstraintSetSubFieldContainer>
          <Stack style={{ minWidth: 116 }}>
            <p>Min</p>
            <SpinnerWithTooltip
              onChange={(_: any, value: number) => {
                updateMin(value);
              }}
              value={convertToView(constraint.min)}
              min={minMin || 0}
              max={maxMax}
              suffix={suffix}
            />
          </Stack>
        </ConstraintSetSubFieldContainer>

        <ConstraintSetSubFieldContainer>
          <p>No limit</p>
          <Stack verticalAlign="center" style={{ height: 30 }}>
            <Toggle
              checked={constraintSetState.infinite}
              onChange={(_, checked) => {
                const value = Boolean(checked);
                updateInfinite(value);
              }}
            />
          </Stack>
        </ConstraintSetSubFieldContainer>

        <ConstraintSetSubFieldContainer>
          <Stack style={{ minWidth: 116 }}>
            <p>Max</p>
            <Stack verticalAlign="center" style={{ height: 30 }}>
              {constraintSetState.infinite ? (
                <p>No limit</p>
              ) : (
                <SpinnerWithTooltip
                  onChange={(_: any, value: number) => {
                    updateMax(value);
                  }}
                  value={convertToView(constraint.max)}
                  min={0}
                  max={maxMax}
                  suffix={suffix}
                  disabled={constraintSetState.infinite}
                />
              )}
            </Stack>
          </Stack>
        </ConstraintSetSubFieldContainer>

        <ConstraintSetSubFieldContainer>
          <Stack style={{ minWidth: 116 }}>
            <p>Default</p>
            <SpinnerWithTooltip
              onChange={(_: any, value: number) => {
                updateDefaultValue(value);
              }}
              value={convertToView(constraint.default)}
              min={constraintSetState.min}
              max={constraintSetState.infinite ? undefined : constraintSetState.max}
              suffix={suffix}
            />
          </Stack>
        </ConstraintSetSubFieldContainer>
      </Stack>
    </Stack>
  );
};

export default ConstraintSetModelField;
