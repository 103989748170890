import { Stack } from '@fluentui/react';
import { Dropdown } from '@h2oai/ui-kit';
import { useState } from 'react';

import SpinnerWithTooltip from '../../SpinnerWithTooltip/SpinnerWithTooltip';
import { TimeUnit, maxSeconds, timeUnitInfoMap } from '../constants';
import { getBestDateTimeUnitInfoPair, parseNumberAndTimeUnitToSeconds } from '../utils';
import { EntityFieldInputProps } from './BasicEntityModelComponents/types';
import { defaultEntityFormRowStyles } from './DefaultEntityFormRowStyles';
import { LabelAndDescription } from './LabelAndDescription';

export const DurationModelField = <EntityModel,>(props: EntityFieldInputProps<EntityModel>) => {
  const { field, model, onChange: onChangeModel } = props;
  const { label, name, description, maximum, referenceName } = field;
  const value = (model as any)[referenceName || name];
  const [numberProp, unitProp] = getBestDateTimeUnitInfoPair(value);
  const [number, setNumber] = useState<number>(numberProp);
  const defaultUnit = TimeUnit.Second;
  const [unitKey, setUnitKey] = useState<TimeUnit>(unitProp?.type || defaultUnit);
  const options = Object.keys(timeUnitInfoMap).map((key) => ({
    key,
    text: timeUnitInfoMap[key].text,
  }));

  return (
    <Stack styles={{ root: defaultEntityFormRowStyles }} tokens={{ childrenGap: 15 }}>
      <LabelAndDescription light label={label} description={description} required={field.required} />
      <Stack horizontal tokens={{ childrenGap: 17 }}>
        <SpinnerWithTooltip
          onChange={(_: any, value: number) => {
            setNumber(value);
            onChangeModel && onChangeModel(referenceName || name, parseNumberAndTimeUnitToSeconds(value, unitKey!));
          }}
          value={number}
          min={0}
          max={maximum || maxSeconds}
        />
        <Dropdown
          options={options}
          selectedKey={unitKey}
          width={200}
          onChange={(_ev, v) => {
            const value = v?.key as TimeUnit;
            setUnitKey(value);
            onChangeModel && onChangeModel(referenceName || name, parseNumberAndTimeUnitToSeconds(number, value));
          }}
        />
      </Stack>
    </Stack>
  );
};
