import { IDropdownOption, ITextFieldProps, MessageBarType } from '@fluentui/react';
import {
  Button,
  Dropdown,
  Info,
  Loader,
  MessageBar,
  TextField,
  buttonStylesPrimary,
  buttonStylesStealth,
  useClassNames,
  useTheme,
  useToast,
} from '@h2oai/ui-kit';
import { default as SchemaForm } from '@rjsf/core';
import Form from '@rjsf/fluent-ui';
import { RJSFSchema } from '@rjsf/utils';
import { UiSchema } from '@rjsf/utils';
import { customizeValidator } from '@rjsf/validator-ajv8';
import Ajv2020 from 'ajv/dist/2020';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Header from '../../components/Header/Header';
import { ExecutorPool } from '../../orchestrator/gen/ai/h2o/orchestrator/v1/executor_pool_pb';
import { GetExecutorPoolResponse } from '../../orchestrator/gen/ai/h2o/orchestrator/v1/executor_pool_service_pb';
import { useOrchestratorService } from '../../orchestrator/hooks';
import { ClassNamesFromIStyles } from '../../utils/models';
import { formatError } from '../../utils/utils';
import { IExecutorPoolDetailStyles, executorPoolDetailStyles } from './ExecutorPoolDetail.styles';
import NavigationWrapper from './NavigationWrapper';
import { useRoles } from './RoleProvider';
import { checkIfNatural } from './WorkflowTabCanvas';
import { useWorkspaces } from './WorkspaceProvider';

// Import ms-Grid and other grid related styles.
import './ExecutorPoolDetail.css';

type ExecutorPoolNavParams = { executor_pool_id: string; workspace_id: string };

export type DropdownOption = { key: string | number; text: string; executorPoolSpecSchema: RJSFSchema };

// Use ajv 2020 for JSON schema validation instead of default ajv 2019 - https://github.com/rjsf-team/react-jsonschema-form/issues/3750.
const validator = customizeValidator({ AjvClass: Ajv2020 });

const uiSchema: UiSchema = {
  'ui:globalOptions': {
    duplicateKeySuffixSeparator: '_',
  },
  environmentalVariables: {
    additionalProperties: {
      'ui:title': 'Value',
    },
  },
};

const ExecutorPoolDetail = () => {
  const history = useHistory(),
    orchestratorService = useOrchestratorService(),
    theme = useTheme(),
    classNames = useClassNames<IExecutorPoolDetailStyles, ClassNamesFromIStyles<IExecutorPoolDetailStyles>>(
      'executorPoolDetail',
      executorPoolDetailStyles(theme)
    ),
    { addToast } = useToast(),
    { permissions } = useRoles(),
    { ACTIVE_WORKSPACE_NAME } = useWorkspaces(),
    params = useParams<ExecutorPoolNavParams>(),
    [executorPool, setExecutorPool] = React.useState<ExecutorPool>(),
    isNew = !executorPool,
    [displayName, setDisplayName] = React.useState(''),
    [executorPoolSpec, setExecutorPoolSpec] = React.useState(''),
    [executorPoolConfig, setExecutorPoolConfig] = React.useState(JSON.parse('{}')),
    [maxQueueSizePerExecutor, setMaxQueueSizePerExecutor] = React.useState<number>(),
    [maxExecutors, setMaxExecutors] = React.useState<number>(),
    [showExecutorPoolValidation, setShowExecutorPoolValidation] = React.useState(false),
    [poolSpecOptions, setPoolSpecOptions] = React.useState<DropdownOption[]>([]),
    [currentSchema, setCurrentSchema] = React.useState<RJSFSchema>(),
    [isActionDisabled, setIsActionDisabled] = React.useState(false),
    [isAdvancedExpanded, setAdvancedExpanded] = React.useState(false),
    formRef = React.createRef<SchemaForm>(),
    [loading, setLoading] = React.useState(true),
    loadStateRef = React.useRef({
      fetchExecutorPool: false,
      fetchExecutorPoolSpecs: false,
    }),
    evaluateLoading = () => {
      if (!loadStateRef.current.fetchExecutorPool && !loadStateRef.current.fetchExecutorPoolSpecs) {
        setLoading(false);
      }
    },
    getExecutorPool = React.useCallback(async () => {
      loadStateRef.current.fetchExecutorPool = true;
      setLoading(true);
      try {
        const data: GetExecutorPoolResponse = await orchestratorService.getExecutorPool({
          name: `workspaces/${params.workspace_id}/executorPools/${params.executor_pool_id}`,
        });
        setExecutorPool(data?.executorPool);
      } catch (err) {
        const message = `Failed to fetch executor pool: ${formatError(err)}`;
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        console.error(message);
        setExecutorPool(undefined);
      } finally {
        loadStateRef.current.fetchExecutorPool = false;
        evaluateLoading();
      }
    }, [orchestratorService, addToast, params.workspace_id, params.executor_pool_id]),
    fetchExecutorPoolSpecs = React.useCallback(async () => {
      loadStateRef.current.fetchExecutorPoolSpecs = true;
      setLoading(true);
      try {
        const data = await orchestratorService.getExecutorPoolSpecs({});
        const options = data?.executorPoolSpecs
          ? data.executorPoolSpecs.map((spec) => ({
              key: spec.name || '',
              text: spec.displayName || '',
              executorPoolSpecSchema: JSON.parse(spec?.executorPoolSpecSchema || '{}'),
            }))
          : [];
        setPoolSpecOptions(options);
        if (!executorPoolSpec && options.length > 0) setExecutorPoolSpec(options[0].key as string);
      } catch (err) {
        const message = `Failed to fetch executorPoolSpecs: ${formatError(err)}`;
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        console.error(message);
      } finally {
        loadStateRef.current.fetchExecutorPoolSpecs = false;
        evaluateLoading();
      }
    }, [orchestratorService, addToast]),
    createExecutorPool = React.useCallback(async () => {
      try {
        setIsActionDisabled(true);
        await orchestratorService.createExecutorPool({
          parent: ACTIVE_WORKSPACE_NAME || '',
          executorPool: {
            displayName,
            executorPoolSpec,
            executorPoolConfig: JSON.stringify(executorPoolConfig),
            maxQueueSizePerExecutor,
            maxExecutors,
          },
        });
        addToast({
          messageBarType: MessageBarType.success,
          message: 'Executor pool created successfully.',
        });
        history.goBack();
      } catch (err) {
        const message = `Failed to create executor pool: ${formatError(err)}`;
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        console.error(message);
      } finally {
        setIsActionDisabled(false);
      }
    }, [
      ACTIVE_WORKSPACE_NAME,
      orchestratorService,
      addToast,
      history,
      // TODO: Set through params.
      displayName,
      executorPoolSpec,
      executorPoolConfig,
      maxQueueSizePerExecutor,
      maxExecutors,
    ]),
    updateExecutorPool = React.useCallback(
      async (name: string) => {
        setIsActionDisabled(true);
        try {
          await orchestratorService.editExecutorPool({
            executorPool: {
              name,
              displayName,
              executorPoolSpec,
              executorPoolConfig: JSON.stringify(executorPoolConfig),
              maxQueueSizePerExecutor,
              maxExecutors,
            },
            updateMask: 'displayName,executorPoolSpec,executorPoolConfig,maxQueueSizePerExecutor,maxExecutors',
          });
          addToast({
            messageBarType: MessageBarType.success,
            message: `${displayName || 'Executor pool'} updated successfully.`,
          });
          history.goBack();
        } catch (err) {
          const message = `Failed to update ${displayName || 'executor pool'}: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        } finally {
          setIsActionDisabled(false);
        }
      },
      [
        orchestratorService,
        addToast,
        history,
        // TODO: Set through params.
        displayName,
        executorPoolSpec,
        executorPoolConfig,
        maxQueueSizePerExecutor,
        maxExecutors,
      ]
    ),
    onActionClick = () => {
      if (
        !displayName ||
        !executorPoolSpec ||
        !executorPoolConfig ||
        (currentSchema && !validator.isValid(currentSchema, executorPoolConfig, {}))
      ) {
        setShowExecutorPoolValidation(true);
        return;
      }
      if (isNew) void createExecutorPool();
      else void updateExecutorPool(executorPool?.name || '');
    },
    onSpecChange = (_ev: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      if (!option) return;
      setExecutorPoolSpec((option.key as string) || '');
    },
    onChangeMaxExecutors = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      if (newValue && checkIfNatural(newValue)) setMaxExecutors(parseInt(newValue));
      if (!newValue) setMaxExecutors(undefined);
    },
    onChangeMaxQueueSize = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      if (newValue && checkIfNatural(newValue)) setMaxQueueSizePerExecutor(parseInt(newValue));
      if (!newValue) setMaxQueueSizePerExecutor(undefined);
    };

  React.useEffect(() => {
    if (!ACTIVE_WORKSPACE_NAME) return;
    // TODO: Cleanup running requests on unmount.
    if (ACTIVE_WORKSPACE_NAME) void fetchExecutorPoolSpecs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ACTIVE_WORKSPACE_NAME, fetchExecutorPoolSpecs]);

  React.useEffect(() => {
    setCurrentSchema(poolSpecOptions.find((option) => option.key === executorPoolSpec)?.executorPoolSpecSchema);
  }, [executorPoolSpec, poolSpecOptions]);

  React.useEffect(() => {
    if (params.executor_pool_id && params.executor_pool_id !== 'create-new') void getExecutorPool();
  }, [params.workspace_id, params.executor_pool_id, getExecutorPool]);

  React.useEffect(() => {
    if (formRef?.current) formRef.current?.submit();
  }, [currentSchema, formRef.current]);

  React.useEffect(() => {
    if (executorPool) {
      setDisplayName(executorPool?.displayName || '');
      setMaxQueueSizePerExecutor(executorPool?.maxQueueSizePerExecutor);
      setMaxExecutors(executorPool?.maxExecutors);
      setExecutorPoolSpec(executorPool?.executorPoolSpec || '');
      try {
        setExecutorPoolConfig(JSON.parse(executorPool?.executorPoolConfig || '{}'));
      } catch (err) {
        const message = `Failed to parse executor pool config: ${formatError(err)}`;
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      }
    }
  }, [executorPool]);

  return (
    <NavigationWrapper>
      <Header
        customPageTitle={`${!permissions.canEditRunnables ? 'View' : isNew ? 'Create' : 'Update'} executor pool`}
        isActionButtonDisabled={isActionDisabled}
      />
      {loading ? (
        <div className={classNames.loader}>
          <Loader label="Loading executor pool detail..." />
        </div>
      ) : (
        <div className={classNames.form}>
          <TextField
            required
            label="Name"
            value={displayName}
            className={classNames.dialogInput}
            readOnly={!permissions.canEditRunnables}
            onChange={(_ev, newValue) => setDisplayName(newValue || '')}
            errorMessage={showExecutorPoolValidation && !displayName ? 'This field cannot be empty.' : ''}
          />
          <Dropdown
            required
            label="Executor pool specification"
            options={poolSpecOptions}
            onChange={onSpecChange}
            selectedKey={executorPoolSpec}
            className={classNames.dialogInput}
            disabled={!permissions.canEditRunnables || !isNew}
            errorMessage={showExecutorPoolValidation && !executorPoolSpec ? 'This field cannot be empty.' : ''}
          />
          {executorPoolSpec && currentSchema ? (
            <>
              <Button
                iconName="ChevronRight"
                text="Advanced settings"
                onClick={(ev) => {
                  const icon = (ev.currentTarget as HTMLElement)?.querySelector('.ms-Icon');
                  if (icon) {
                    setAdvancedExpanded(!icon.classList?.contains('rotate90'));
                    icon.classList.toggle('rotate90');
                  }
                }}
                styles={[
                  buttonStylesStealth,
                  {
                    icon: {
                      fontSize: 14,
                      transitionDuration: '300ms',
                      transitionProperty: 'transform',
                    },
                    root: {
                      marginTop: 10,
                      '.ms-Icon.rotate90': {
                        transform: 'rotate(90deg)',
                      },
                      backgroundColor: 'transparent',
                    },
                  },
                ]}
              />
              <span
                // Hides form istead of removing it to keep form data for submission when advanced settings are collapsed.
                style={isAdvancedExpanded ? undefined : { visibility: 'hidden', position: 'fixed' }}
              >
                <MessageBar messageBarType={5} className={classNames.messageBar}>{`The ${
                  poolSpecOptions.find((spec) => spec.key === executorPoolSpec)?.text || 'executor pool specification'
                } is not editable ${isNew ? 'after once saved' : 'it was once saved'}.`}</MessageBar>
                <Form
                  ref={formRef}
                  id={'executor-pool-config-form'}
                  validator={validator}
                  schema={currentSchema}
                  formData={executorPoolConfig}
                  onChange={(schema) => {
                    setExecutorPoolConfig(schema.formData);
                  }}
                  liveValidate
                  showErrorList={showExecutorPoolValidation ? 'bottom' : false}
                  readonly={!permissions.canEditRunnables || !isNew}
                  className={classNames.formEditor}
                  uiSchema={uiSchema}
                >
                  <>{/* Specify empty body to hide form submit button. */}</>
                </Form>
                <div className={classNames.expandedContentItem}>
                  <TextField
                    type="number"
                    label="Max executors"
                    onChange={onChangeMaxExecutors}
                    value={`${maxExecutors}`}
                    className={classNames.dialogInput}
                    styles={{ field: { paddingRight: 0 } }}
                    readOnly={!permissions.canEditRunnables}
                    aria-labelledby="max-exec-field"
                    onRenderLabel={(props?: ITextFieldProps) => (
                      <div id={'max-exec-field'} className={classNames.infoLabel}>
                        {props?.label}
                        <Info isTooltip className={classNames.tooltip}>
                          How many executors can be created in this pool. If not specified, executors limit is infinity.
                        </Info>
                      </div>
                    )}
                  />
                </div>
                <div className={classNames.expandedContentItem}>
                  <TextField
                    type="number"
                    label="Max queue size per executor"
                    value={`${maxQueueSizePerExecutor}`}
                    onChange={onChangeMaxQueueSize}
                    className={classNames.dialogInput}
                    styles={{ field: { paddingRight: 0 } }}
                    aria-labelledby="max-queue-field"
                    readOnly={!permissions.canEditRunnables}
                    onRenderLabel={(props?: ITextFieldProps) => (
                      <div id={'max-queue-field'} className={classNames.infoLabel}>
                        {props?.label}
                        <Info isTooltip className={classNames.tooltip}>
                          New executor will be created every time when queue size reach this limit. If size is not set,
                          only one executor will be created for this pool.
                        </Info>
                      </div>
                    )}
                  />
                </div>
              </span>
            </>
          ) : null}
          {permissions.canEditRunnables ? (
            <Button
              onClick={onActionClick}
              styles={buttonStylesPrimary}
              text="Save changes"
              className={classNames.footerButton}
            />
          ) : null}
        </div>
      )}
    </NavigationWrapper>
  );
};

export default ExecutorPoolDetail;
