import { ISpinButtonStyles, ITextStyles } from '@fluentui/react';
import { FontSizes, IH2OTheme, Sizes } from '@h2oai/ui-kit';

export const fluentNumberFieldDefault = (theme: IH2OTheme): Partial<ISpinButtonStyles> => ({
  spinButtonWrapper: {
    border: `1px solid ${theme.semanticColors?.inputBorder}`,
    borderRadius: Sizes.borderRadius,
    height: Sizes.height,
    ':hover': {
      borderColor: theme.semanticColors?.inputActiveBorder,
    },
    ':focus': {
      borderColor: theme.semanticColors?.inputActiveBorder,
    },
    '::after': {
      display: 'none',
    },
    '.is-active': { borderColor: theme.semanticColors?.inputActiveBorder },
  },
  input: {
    color: theme.semanticColors?.inputText,
    lineHeight: Sizes.height - 2,
    paddingLeft: Sizes.padding,
    paddingRight: Sizes.padding,
    '::placeholder': {
      color: theme.semanticColors?.inputPlaceholder,
    },
  },
  arrowButtonsContainer: { color: theme.semanticColors?.inputText },
});

export const helpTextDefault = (theme: IH2OTheme): Partial<ITextStyles> => ({
  root: {
    color: theme.palette?.gray500,
    display: 'block',
    fontSize: FontSizes.xxsmall,
    position: 'absolute',
    right: 0,
    textAlign: 'right',
  },
});

export const fieldLabelStyles = (_theme: IH2OTheme): Partial<ITextStyles> => ({
  root: {
    position: 'relative',
  },
});

export const requiredFieldLabelStyles = (theme: IH2OTheme): Partial<ITextStyles> => ({
  root: {
    ':after': {
      content: '" *"',
      color: theme.palette?.red500,
      marginLeft: 4,
    },
  },
});
