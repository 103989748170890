import { EntityType } from '../../components/AdminSettings/Entity/constants';
import type { Entity } from '../../components/AdminSettings/Entity/types';
import { kernelImageEntity } from './KernelImage.entity';
import { kernelSpecEntity } from './KernelSpec.entity';
import { kernelTemplateEntity } from './KernelTemplate.entity';

export const EntitiesMap = new Map<EntityType, Entity<any>>([
  [EntityType.KernelImage, kernelImageEntity],
  [EntityType.KernelTemplate, kernelTemplateEntity],
  [EntityType.KernelSpec, kernelSpecEntity],
]);
