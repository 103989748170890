import { IContextualMenuItem, MessageBarType } from '@fluentui/react';
import { Button, ConfirmDialog, buttonStylesIcon, useToast } from '@h2oai/ui-kit';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { FailedToLoadView } from '../../components/FailedToLoadView/FailedToLoadView';
import Header from '../../components/Header/Header';
import { NoItemView } from '../../components/NoItemView/NoItemView';
import { RowHeaderTitle } from '../../components/RowHeaderTitle/RowHeaderTitle';
import WidgetList from '../../components/WidgetList/WidgetList';
import { ExecuteWorkflowResponse } from '../../orchestrator/gen/ai/h2o/orchestrator/v1/workflow_service_pb';
import { useOrchestratorService } from '../../orchestrator/hooks';
import { formatError } from '../../utils/utils';
import { ENDPOINTS } from './apiEndpoints';
import NavigationWrapper from './NavigationWrapper';
import { useRoles } from './RoleProvider';
import { ListWorkflowsResponseFixed, WorkflowFixed } from './WorkflowTabCanvas';
import { useWorkspaces } from './WorkspaceProvider';

type WorkflowItem = WorkflowFixed & {
  stepCount: number;
  viewOnly?: boolean;
  createdTimeLocal?: string;
  canRun?: boolean;
  onClickEdit: () => void;
  onClickDelete: () => void;
  onClickRun: () => void;
  onClickManageTriggers: () => void;
  onClickManageExecutions: () => void;
};

export const ContextMenuIconButton = ({ items }: { items: IContextualMenuItem[] }) => (
  <Button
    title="More"
    styles={[buttonStylesIcon, { menuIcon: { display: 'none' } }]}
    iconProps={{ style: { margin: 0 }, iconName: 'More' }}
    menuProps={{
      items,
      directionalHintFixed: true,
      calloutProps: { styles: { root: { padding: '6px 0px' } } },
    }}
  />
);

const columns = [
  {
    key: 'title',
    name: 'Title',
    fieldName: 'name',
    minWidth: 200,
    maxWidth: 800,
    data: {
      headerFieldName: 'displayName',
      listCellProps: {
        onRenderHeader: ({ displayName, onClickEdit }: WorkflowItem) =>
          RowHeaderTitle({ title: displayName, onClick: onClickEdit }),
        iconProps: {
          iconName: 'Flow',
        },
      },
    },
  },
  {
    key: 'steps',
    name: 'Steps',
    fieldName: 'stepCount',
    minWidth: 80,
    maxWidth: 100,
  },
  {
    key: 'createdAt',
    name: 'Created at',
    fieldName: 'createTimeLocal',
    minWidth: 150,
    maxWidth: 250,
  },
  {
    key: 'createdBy',
    name: 'Created by',
    fieldName: 'creatorDisplayName',
    minWidth: 100,
    maxWidth: 200,
  },
  {
    key: 'buttons',
    name: '',
    minWidth: 200,
    data: {
      listCellProps: {
        emptyMessage: 'No Description',
        onRenderText: ({
          onClickDelete,
          onClickEdit,
          onClickRun,
          onClickManageTriggers,
          onClickManageExecutions,
          viewOnly,
          canRun,
        }: WorkflowItem) => (
          // TODO: Use theme prop for colors.
          <ContextMenuIconButton
            items={[
              {
                key: viewOnly ? 'view' : 'edit',
                text: viewOnly ? 'View' : 'Edit',
                onClick: onClickEdit,
                iconProps: { iconName: viewOnly ? 'RedEye' : 'Edit', style: { color: 'var(--h2o-gray900)' } },
              },
              {
                key: viewOnly ? 'viewTriggers' : 'manageTriggers',
                text: viewOnly ? 'View triggers' : 'Manage triggers',
                onClick: onClickManageTriggers,
                iconProps: {
                  iconName: 'TriggerAuto',
                  style: { color: 'var(--h2o-gray900)' },
                },
              },
              {
                key: viewOnly ? 'viewExecutions' : 'manageExecutions',
                text: viewOnly ? 'View executions' : 'Manage executions',
                onClick: onClickManageExecutions,
                iconProps: {
                  iconName: 'ChevronRight',
                  style: { color: 'var(--h2o-gray900)' },
                },
              },
              {
                key: 'run',
                text: 'Run workflow',
                onClick: onClickRun,
                iconProps: { iconName: 'Play', style: { color: 'var(--h2o-blue500)' } },
                style: { color: 'var(--h2o-blue500)', display: canRun ? undefined : 'none' },
              },
              {
                key: 'delete',
                text: 'Delete',
                onClick: onClickDelete,
                style: { color: 'var(--h2o-red400)', display: viewOnly ? 'none' : undefined },
                iconProps: {
                  iconName: 'Delete',
                  style: { color: 'var(--h2o-red400)' },
                },
              },
            ]}
          />
        ),
        styles: {
          root: {
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'end',
          },
        },
      },
    },
  },
];

const Workflows = () => {
  const history = useHistory(),
    { ACTIVE_WORKSPACE_NAME } = useWorkspaces(),
    { permissions } = useRoles(),
    onAction = () => history.push(`/orchestrator/${ACTIVE_WORKSPACE_NAME}${ENDPOINTS.WORKFLOWS}/create-new`),
    orchestratorService = useOrchestratorService(),
    { addToast } = useToast(),
    [workflowItems, setWorkflowItems] = React.useState<WorkflowItem[]>(),
    // TODO: Handle loading state.
    [loading, setLoading] = React.useState(true),
    [isLoadingSearch, setIsLoadingSearch] = React.useState(false),
    [isLoadingMore, setIsLoadingMore] = React.useState(false),
    [isSearchStr, setIsSearchStr] = React.useState(false),
    [nextPageToken, setNextPageToken] = React.useState<string>(),
    [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false),
    [dialogAction, setDialogAction] = React.useState<() => unknown>(),
    closeDeleteDialog = () => setIsDeleteDialogOpen(false),
    onEditWorkflow = (workflow: WorkflowFixed) => history.push(`/orchestrator/${workflow.name}`, { state: workflow }),
    onDeleteWorkflow = (name: string) => {
      setDialogAction(() => () => {
        void deleteWorkflow(name);
        closeDeleteDialog();
      });
      setIsDeleteDialogOpen(true);
    },
    runWorkflow = async (name: string) => {
      await orchestratorService
        .executeWorkflow({ name })
        .then((_data: ExecuteWorkflowResponse) => {
          addToast({
            messageBarType: MessageBarType.success,
            message: `Workflow ${name} is running.`,
          });
          history.push(`/orchestrator/${ACTIVE_WORKSPACE_NAME}/activeExecutions`);
        })
        .catch((err) => {
          const message = `Failed to run workflow: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        });
    },
    fetchWorkflows = React.useCallback(
      async (pageToken?: string, filter?: string) => {
        if (pageToken) setIsLoadingMore(true);
        else if (filter || filter === `display_name = ""`) setIsLoadingSearch(true);
        else setLoading(true);
        try {
          const data: ListWorkflowsResponseFixed = await orchestratorService.getWorkflows({
            parent: ACTIVE_WORKSPACE_NAME || '',
            pageSize: 20,
            pageToken,
            orderBy: 'create_time desc',
            filter: filter === `display_name = ""` ? undefined : filter,
          });
          const newWorkflowItems: WorkflowItem[] | undefined = data?.workflows
            ? data.workflows.map((workflow) => ({
                ...workflow,
                stepCount: workflow.steps?.length || 0,
                createTimeLocal: workflow.createTime ? new Date(workflow.createTime).toLocaleString() : undefined,
                onClickEdit: () => onEditWorkflow(workflow),
                onClickManageTriggers: () => history.push(`/orchestrator/${workflow.name}/triggers`),
                onClickManageExecutions: () => history.push(`/orchestrator/${workflow.name}/executions`),
                // TODO: Handle if name is missing.
                onClickDelete: () => onDeleteWorkflow(workflow.name || ''),
                onClickRun: () => runWorkflow(workflow.name || ''),
                viewOnly: !permissions.canEditWorkflows,
                canRun: permissions.canRunWorkflows,
              }))
            : undefined;
          if (data && !newWorkflowItems) console.error('No workflows found in the response.');
          setNextPageToken(data?.nextPageToken || undefined);
          setWorkflowItems((items) => (pageToken ? [...(items || []), ...(newWorkflowItems || [])] : newWorkflowItems));
        } catch (err) {
          const message = `Failed to fetch workflows: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        } finally {
          setIsLoadingMore(false);
          setLoading(false);
          setIsLoadingSearch(false);
        }
      },
      [ACTIVE_WORKSPACE_NAME, orchestratorService, permissions]
    ),
    deleteWorkflow = React.useCallback(
      async (name: string) => {
        setLoading(true);
        try {
          await orchestratorService.deleteWorkflow({ name });
          void fetchWorkflows();
        } catch (err) {
          const message = `Failed to delete workflow: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        } finally {
          setLoading(false);
        }
      },
      [orchestratorService, fetchWorkflows]
    );

  React.useEffect(() => {
    if (ACTIVE_WORKSPACE_NAME) void fetchWorkflows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ACTIVE_WORKSPACE_NAME]);

  React.useEffect(() => {
    setWorkflowItems((items) =>
      items?.[0]?.viewOnly === !permissions.canEditWorkflows && items?.[0]?.canRun === permissions.canRunWorkflows
        ? items
        : items?.map((item) => ({
            ...item,
            viewOnly: !permissions.canEditWorkflows,
            canRun: permissions.canRunWorkflows,
          }))
    );
  }, [permissions]);

  return (
    <NavigationWrapper>
      {workflowItems?.length || isSearchStr || loading ? <Header /> : null}
      <ConfirmDialog
        title="Delete Workflow"
        hidden={!isDeleteDialogOpen}
        onConfirm={dialogAction!}
        onDismiss={closeDeleteDialog}
        msg="Are you sure you want to delete this workflow? Once it is deleted it cannot be restored."
        confirmationButtonText="Delete"
        dismissalButtonText="Cancel"
        // TODO: Add danger button style.
      />
      <WidgetList
        columns={columns}
        items={workflowItems}
        loading={loading}
        isLoadingSearch={isLoadingSearch}
        isLoadingMore={isLoadingMore}
        onLoadMore={nextPageToken ? () => void fetchWorkflows(nextPageToken) : undefined}
        searchProps={{
          placeholder: 'Search workflows',
          onSearchChange: (value) => {
            setIsSearchStr(!!value);
            void fetchWorkflows(undefined, `display_name = '${value}'`);
          },
        }}
        actionProps={
          permissions.canEditWorkflows
            ? {
                actionIcon: 'Add',
                actionTitle: 'Create workflow',
                onActionClick: onAction,
              }
            : undefined
        }
        NoItemsContent={NoItemView({
          title: 'Workflows',
          description: `There are no workflows created in this workspace. ${
            permissions.canEditWorkflows ? 'Create the first one.' : ''
          }`,
          actionTitle: permissions.canEditWorkflows ? 'Create workflow' : undefined,
          onActionClick: onAction,
          actionIcon: 'Add',
          backgroundImage: `url(${require('./assets/no-workflows-background.gif')})`,
        })}
        ErrorContent={FailedToLoadView({
          title: 'Failed to load workflows',
          description: 'Please try again later. If the problem persists, contact our support.',
          actionTitle: 'Retry',
          onActionClick: fetchWorkflows,
          actionIcon: 'Refresh',
        })}
      />
    </NavigationWrapper>
  );
};

export default Workflows;
