import Header from '../../components/Header/Header';
import Executions from './Executions';
import NavigationWrapper from './NavigationWrapper';

const ActiveExecutions = () => {
  return (
    <NavigationWrapper>
      {/* TODO: Show/hide header. */}
      {/* {activeExecutionsItems?.length && !loading ? <Header /> : null} */}
      <Header customPageTitle="Active executions" />
      <Executions filterBy="running" />
    </NavigationWrapper>
  );
};

export default ActiveExecutions;
