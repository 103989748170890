import { PanelType } from '@fluentui/react';
import { Panel } from '@h2oai/ui-kit';

import { AddEditModelForm } from '../../../components/AdminSettings/components/AddEditModelForm/AddEditModelForm';
import type { Entity, HasName } from '../../../components/AdminSettings/Entity/types';
import { stylesPanel } from '../../../components/AIEnginesPage/components/AIEMPanel/AIEMPanel';

export interface KernelSettingsEditPanelProps<EntityModel> {
  panelTitle: string;
  onDismiss: () => any;
  onSave: () => any;
  isCreate: boolean;
  entity: Entity<EntityModel>;
  model?: EntityModel;
}

export const KernelSettingsEditPanel = <EntityModel extends HasName>(
  props: KernelSettingsEditPanelProps<EntityModel>
) => {
  const { panelTitle, onDismiss, isCreate, onSave, entity, model } = props;

  if (!model) return <></>;

  return (
    <Panel
      isLightDismiss
      customWidth="500px"
      headerText={panelTitle}
      isFooterAtBottom
      isOpen={true}
      onDismiss={onDismiss}
      type={PanelType.custom}
      styles={stylesPanel}
    >
      <AddEditModelForm<EntityModel>
        entity={entity}
        model={model}
        isCreate={isCreate}
        onSave={onSave}
        onDismiss={onDismiss}
      />
    </Panel>
  );
};
