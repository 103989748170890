import { MessageBarType } from '@fluentui/react';
import { useToast } from '@h2oai/ui-kit';
import React from 'react';

import { ListUsersResponse } from '../../authz/gen/ai/h2o/user/v1/user_api_pb';
import { User } from '../../authz/gen/ai/h2o/user/v1/user_pb';
import { useAuthzService } from '../../authz/hooks';
import { useUser } from '../../utils/hooks';
import { formatError } from '../../utils/utils';

type UsersContextType = {
  users?: User[];
  listUsers: (searchFilter: string) => Promise<User[] | undefined>;
  CURRENT_USER_NAME?: string;
};

const UsersContext = React.createContext<UsersContextType | undefined>(undefined);

const UsersProvider = ({ children }: { children: React.ReactNode }) => {
  const authzService = useAuthzService(),
    { addToast } = useToast(),
    user = useUser(),
    CURRENT_USER_NAME = `users/${user.id}`,
    [users, setUsers] = React.useState<User[] | undefined>(),
    // TODO: Handle loading state.
    [, setLoading] = React.useState(true),
    fetchUsers = React.useCallback(async () => {
      setLoading(true);
      try {
        let userItems: User[] = [],
          morePagesAvailable = true,
          nextPageToken;

        while (morePagesAvailable) {
          // TODO: Prevent performance issues when lot of users - fetch only users with role bindings to current workspace.
          const data: ListUsersResponse = await authzService.getUsers({ pageToken: nextPageToken });
          userItems = [...userItems, ...(data?.users || [])];
          morePagesAvailable = !!data.nextPageToken;
          nextPageToken = data?.nextPageToken;
        }

        if (!userItems) console.error('No users found in the response.');
        setUsers(userItems);
      } catch (err) {
        const message = `Failed to fetch users: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setUsers(undefined);
      } finally {
        setLoading(false);
      }
    }, [authzService, addToast, setUsers, setLoading]),
    listUsers = async (searchFilter: string) => {
      try {
        const data = await authzService.getUsers({ filter: `'${searchFilter}'` });
        const userItems: User[] | undefined = data?.users;
        if (data && !data?.users) console.error('No users found in the response.');
        return userItems;
      } catch (err) {
        const message = `Failed to fetch users: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        return undefined;
      }
    };

  React.useEffect(() => {
    void fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    users,
    listUsers,
    CURRENT_USER_NAME,
  };

  return <UsersContext.Provider value={value}>{children}</UsersContext.Provider>;
};

const useUsers = () => {
  const context = React.useContext(UsersContext);
  if (!context) {
    throw new Error('useUsers must be used within a UserProvider');
  }
  return context;
};

export { UsersProvider, useUsers };
