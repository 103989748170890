// @generated by protoc-gen-grpc-gateway-es v0.1.6 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/notebook/v1/notebook_kernel_spec_service.proto (package ai.h2o.notebook.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { NotebookKernelSpec } from "./notebook_kernel_spec_pb";
import { RPC } from "../../../../runtime";

/**
 * Request message for `CreateNotebookKernelSpec`.
 *
 * @generated from message ai.h2o.notebook.v1.CreateNotebookKernelSpecRequest
 */
export type CreateNotebookKernelSpecRequest = {
/**
 * Required. The resource name of the workspace to associate with the NotebookKernelSpec.
 * Format is `workspaces/*`.
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * Required. The NotebookKernelSpec resource to create.
 *
 * @generated from field: ai.h2o.notebook.v1.NotebookKernelSpec notebook_kernel_spec = 2;
 */
notebookKernelSpec: NotebookKernelSpec;
/**
 * Optional. Specify the NotebookKernelSpec ID, which will become a part of the NotebookKernelSpec
 * resource name. If not specified, the server will generate one.
 *
 * It must:
 * - contain 1-63 characters
 * - contain only lowercase alphanumeric characters or hyphen ('-')
 * - start with an alphabetic character
 * - end with an alphanumeric character
 *
 * This matches a regular expression: ^[a-z]([a-z0-9-]{0,61}[a-z0-9])?$
 *
 * @generated from field: string notebook_kernel_spec_id = 3;
 */
notebookKernelSpecId?: string;
}
;
/**
 * Response message for `CreateNotebookKernelSpec`.
 *
 * @generated from message ai.h2o.notebook.v1.CreateNotebookKernelSpecResponse
 */
export type CreateNotebookKernelSpecResponse = {
/**
 * The created NotebookKernelSpec.
 *
 * @generated from field: ai.h2o.notebook.v1.NotebookKernelSpec notebook_kernel_spec = 1;
 */
notebookKernelSpec?: NotebookKernelSpec;
}
;
/**
 * Request message for `GetNotebookKernelSpec`.
 *
 * @generated from message ai.h2o.notebook.v1.GetNotebookKernelSpecRequest
 */
export type GetNotebookKernelSpecRequest = {
/**
 * Required. The name of the NotebookKernelSpec to retrieve.
 * Format is `workspaces/*\/notebookKernelSpecs/*`.
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for `GetNotebookKernelSpec`.
 *
 * @generated from message ai.h2o.notebook.v1.GetNotebookKernelSpecResponse
 */
export type GetNotebookKernelSpecResponse = {
/**
 * The requested NotebookKernelSpec.
 *
 * @generated from field: ai.h2o.notebook.v1.NotebookKernelSpec notebook_kernel_spec = 1;
 */
notebookKernelSpec?: NotebookKernelSpec;
}
;
/**
 * Request message for listing NotebookKernelSpecs.
 *
 * @generated from message ai.h2o.notebook.v1.ListNotebookKernelSpecsRequest
 */
export type ListNotebookKernelSpecsRequest = {
/**
 * Required. The resource name of the workspace to associate with the NotebookKernelSpec.
 * Format is `workspaces/*`.
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * Optional. Maximum number of NotebookKernelSpecs to return in a response.
 * If unspecified (or set to 0), at most 50 NotebookKernelSpecs will be returned.
 * The maximum value is 1000; values above 1000 will be coerced to 1000.
 *
 * @generated from field: int32 page_size = 2;
 */
pageSize?: number;
/**
 * Optional. Leave unset to receive the initial page.
 * To list any subsequent pages use the value of 'next_page_token'
 * returned from the ListNotebookKernelSpecsResponse.
 *
 * @generated from field: string page_token = 3;
 */
pageToken?: string;
}
;
/**
 * Response message for ListNotebookKernelSpecs.
 *
 * @generated from message ai.h2o.notebook.v1.ListNotebookKernelSpecsResponse
 */
export type ListNotebookKernelSpecsResponse = {
/**
 * Collection of NotebookKernelSpecs.
 *
 * @generated from field: repeated ai.h2o.notebook.v1.NotebookKernelSpec notebook_kernel_specs = 1;
 */
notebookKernelSpecs?: NotebookKernelSpec[];
/**
 * Used to retrieve the next page of results.
 * When unset, no further items are available (this response was the last page).
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
}
;
/**
 * Request message for updating an existing NotebookKernelSpec.
 *
 * @generated from message ai.h2o.notebook.v1.UpdateNotebookKernelSpecRequest
 */
export type UpdateNotebookKernelSpecRequest = {
/**
 * Required. NotebookKernelSpec resource.
 *
 * @generated from field: ai.h2o.notebook.v1.NotebookKernelSpec notebook_kernel_spec = 1;
 */
notebookKernelSpec: NotebookKernelSpec;
/**
 * Required. The list of paths referencing which fields to update.
 * Update mask must be non-empty.
 *
 * Allowed field paths are:
 * - display_name
 * - kernel_image
 * - kernel_template
 * - disabled
 * Paths are case sensitive (must match exactly).
 *
 * To update all allowed fields, specify exactly one path with value "*".
 *
 * @generated from field: google.protobuf.FieldMask update_mask = 10;
 */
updateMask: string;
}
;
/**
 * Response message for UpdateNotebookKernelSpec.
 *
 * @generated from message ai.h2o.notebook.v1.UpdateNotebookKernelSpecResponse
 */
export type UpdateNotebookKernelSpecResponse = {
/**
 * NotebookKernelSpec resource.
 *
 * @generated from field: ai.h2o.notebook.v1.NotebookKernelSpec notebook_kernel_spec = 1;
 */
notebookKernelSpec?: NotebookKernelSpec;
}
;
/**
 * Request message for deleting an existing NotebookKernelSpec.
 *
 * @generated from message ai.h2o.notebook.v1.DeleteNotebookKernelSpecRequest
 */
export type DeleteNotebookKernelSpecRequest = {
/**
 * Required. NotebookKernelSpec resource name.
 * Format is `workspaces/*\/notebookKernelSpecs/*`.
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for DeleteNotebookKernelSpec.
 *
 * @generated from message ai.h2o.notebook.v1.DeleteNotebookKernelSpecResponse
 */
export type DeleteNotebookKernelSpecResponse = {
}
;
/**
 * Creates a new NotebookKernelSpec.
 *
 * @generated from rpc ai.h2o.notebook.v1.NotebookKernelSpecService.CreateNotebookKernelSpec
 */
export const NotebookKernelSpecService_CreateNotebookKernelSpec = new RPC<CreateNotebookKernelSpecRequest, CreateNotebookKernelSpecResponse>("POST", "/v1/{parent=workspaces/*}/notebookKernelSpecs", "notebookKernelSpec");
/**
 * Returns a specific NotebookKernelSpec.
 *
 * @generated from rpc ai.h2o.notebook.v1.NotebookKernelSpecService.GetNotebookKernelSpec
 */
export const NotebookKernelSpecService_GetNotebookKernelSpec = new RPC<GetNotebookKernelSpecRequest, GetNotebookKernelSpecResponse>("GET", "/v1/{name=workspaces/*/notebookKernelSpecs/*}");
/**
 * Returns a collection of NotebookKernelSpecs.
 *
 * @generated from rpc ai.h2o.notebook.v1.NotebookKernelSpecService.ListNotebookKernelSpecs
 */
export const NotebookKernelSpecService_ListNotebookKernelSpecs = new RPC<ListNotebookKernelSpecsRequest, ListNotebookKernelSpecsResponse>("GET", "/v1/{parent=workspaces/*}/notebookKernelSpecs");
/**
 * Updates a NotebookKernelSpec.
 *
 * @generated from rpc ai.h2o.notebook.v1.NotebookKernelSpecService.UpdateNotebookKernelSpec
 */
export const NotebookKernelSpecService_UpdateNotebookKernelSpec = new RPC<UpdateNotebookKernelSpecRequest, UpdateNotebookKernelSpecResponse>("PATCH", "/v1/{notebookKernelSpec.name=workspaces/*/notebookKernelSpecs/*}", "notebookKernelSpec");
/**
 * Deletes a NotebookKernelSpec.
 *
 * @generated from rpc ai.h2o.notebook.v1.NotebookKernelSpecService.DeleteNotebookKernelSpec
 */
export const NotebookKernelSpecService_DeleteNotebookKernelSpec = new RPC<DeleteNotebookKernelSpecRequest, DeleteNotebookKernelSpecResponse>("DELETE", "/v1/{name=workspaces/*/notebookKernelSpecs/*}");
