import { IStyle, MessageBarType } from '@fluentui/react';
import {
  Button,
  IBasicColumn,
  IColumnWithDataType,
  IH2OTheme,
  Info,
  ListRow,
  TextField,
  useClassNames,
  useTheme,
  useToast,
} from '@h2oai/ui-kit';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Workflow } from '../../orchestrator/gen/ai/h2o/orchestrator/v1/workflow_pb';
import { useOrchestratorService } from '../../orchestrator/hooks';
import { ClassNamesFromIStyles } from '../../utils/models';
import { formatError } from '../../utils/utils';
import { RowData } from './WorkflowTabCanvas';
import { useWorkspaces } from './WorkspaceProvider';

export interface IWorkflowCanvasWidget {
  defaultWorkflow?: Workflow;
  workflowName: string;
  isSaved: boolean;
  rowData: any;
  timeout: string | null;
  onChangeTimeout: (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => void;
  concurrencyLimit: number;
  onChangeConcurrencyLimit: (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => void;
  readOnly?: boolean;
  canRun?: boolean;
}

interface IWorkflowCanvasWidgetStyles {
  row: IStyle;
  description: IStyle;
  nameLabel: IStyle;
  widgetContentTitle: IStyle;
  widgetExpandedContent: IStyle;
  widgetExpandedContentItem: IStyle;
  tooltip: IStyle;
}

const workflowCanvasWidgetStyles = (theme: IH2OTheme): IWorkflowCanvasWidgetStyles => {
    return {
      row: {
        backgroundColor: theme.semanticColors?.contentBackground,
      },
      description: {
        display: 'flex',
        $nest: {
          '& p': {
            margin: '0px 16px',
            fontSize: '0.9em',
          },
        },
      },
      nameLabel: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'auto',
        maxWidth: 200,
      },
      widgetExpandedContent: {
        flexWrap: 'nowrap',
        display: 'flex',
        maxWidth: 400,
        alignItems: 'center',
        marginLeft: 27,
        paddingBottom: 10,
      },
      widgetExpandedContentItem: {
        flexWrap: 'nowrap',
        display: 'flex',
        padding: 10,
      },
      tooltip: {
        padding: 4,
      },
      widgetContentTitle: {
        margin: 0,
        marginLeft: 42,
        paddingTop: 10,
      },
    };
  },
  runButtonStyles = {
    flexContainer: { width: '100%' },
    root: {
      display: 'flex',
      alignItems: 'center',
      marginRight: 40,
      width: 84,
      borderColor: 'var(--h2o-blue500)',
      color: 'var(--h2o-blue500)',
      '&:hover': {
        color: 'var(--h2o-white)',
        backgroundColor: 'var(--h2o-blue500)',
        background: 'var(--h2o-blue500)',
        border: '1px solid var(--h2o-blue500)',
      },
      '&:active': {
        backgroundColor: 'var(--h2o-white)',
        color: 'var(--h2o-blue500)',
        borderColor: 'var(--h2o-blue500)',
        border: '1px solid var(--h2o-blue500)',
      },
      '&:hover .ms-Button-container': {
        display: 'none',
      },
      '&:hover .ms-Button-textContainer': {
        display: 'none',
      },
      '&:hover .ms-Button-label': {
        color: 'var(--h2o-white)',
      },
      '&:hover .ms-Button-icon': {
        color: 'var(--h2o-white)',
        margin: 0,
        fontWeight: 'bold',
      },
    },
  },
  runButtonStylesDisabled = {
    flexContainer: { width: '100%' },
    root: {
      display: 'flex',
      alignItems: 'center',
      marginRight: 40,
      width: 84,
    },
  },
  widgetStyles = {
    row: {
      '&:hover': {
        background: 'transparent',
      },
      '.h2o-ListCell-root': {
        padding: 0,
      },
      '.h2o-ListCell-header': {
        paddingBottom: 0,
      },
      '.h2o-ListCell-icon': {
        padding: '0px 15px',
        '>div': {
          height: '50px !important',
          width: '50px !important',
        },
      },
      height: 70,
    },
  };

const WorkflowCanvasWidget = ({
  defaultWorkflow,
  workflowName,
  isSaved,
  rowData,
  timeout,
  onChangeTimeout,
  concurrencyLimit,
  onChangeConcurrencyLimit,
  canRun,
  readOnly,
}: IWorkflowCanvasWidget) => {
  const theme = useTheme(),
    classNames = useClassNames<IWorkflowCanvasWidgetStyles, ClassNamesFromIStyles<IWorkflowCanvasWidgetStyles>>(
      'workflowCanvasWidget',
      workflowCanvasWidgetStyles(theme)
    ),
    { addToast } = useToast(),
    orchestratorService = useOrchestratorService(),
    history = useHistory(),
    { ACTIVE_WORKSPACE_NAME } = useWorkspaces(),
    [widgetExpanded, setWidgetExpanded] = React.useState(false),
    [isActionDisabled, setIsActionDisabled] = React.useState(false),
    handleWidgetOpen = () => {
      setWidgetExpanded(!widgetExpanded);
    },
    runWorkflow = React.useCallback(async () => {
      setIsActionDisabled(true);
      try {
        await orchestratorService.executeWorkflow({
          name: defaultWorkflow?.name || '',
        });
        addToast({
          messageBarType: MessageBarType.success,
          message: `Workflow ${workflowName} is running.`,
        });
        history.push(`/orchestrator/${ACTIVE_WORKSPACE_NAME}/activeExecutions`);
      } catch (err) {
        const message = `Failed to run workflow: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      } finally {
        setIsActionDisabled(false);
      }
    }, [orchestratorService, defaultWorkflow, addToast, history, workflowName, ACTIVE_WORKSPACE_NAME]),
    // TODO: Move to upper scope once find out how to use classNames outside of component.
    rowColumns: IColumnWithDataType[] | IBasicColumn[] = [
      {
        key: 'widgetItem',
        name: 'Widget Item',
        minWidth: 350,
        data: {
          headerFieldName: 'title',
          listCellProps: {
            emptyMessage: '',
            iconProps: {
              iconName: 'Dataflows',
            },
            onRenderHeader: ({ titleValue, onTitleChange, showValidation, readOnly }: RowData) => (
              <TextField
                style={{ fontWeight: 600 }}
                placeholder="Workflow name"
                value={titleValue}
                onChange={(_ev, newValue) => onTitleChange(newValue || '')}
                errorMessage={showValidation && !workflowName ? 'This field cannot be empty.' : ''}
                readOnly={readOnly}
              />
            ),
          },
        },
      },
      {
        key: 'actions',
        minWidth: 100,
        flexGrow: 1,
        name: '',
        data: {
          listCellProps: {
            emptyMessage: 'No Description',
            onRenderText: ({ creator, creatorDisplayName, createTimeLocal }: RowData) => (
              <div className={classNames.description}>
                {creator && (
                  <div>
                    <p>
                      <b>Creator</b>
                    </p>
                    <p className={classNames.nameLabel}>{creatorDisplayName}</p>
                  </div>
                )}
                {createTimeLocal && (
                  <div>
                    <p>
                      <b>Created at</b>
                    </p>
                    <p>{createTimeLocal}</p>
                  </div>
                )}
                {defaultWorkflow?.name && canRun && (
                  <Button
                    iconProps={{
                      styles: { root: { color: isActionDisabled ? undefined : 'var(--h2o-blue500)' } },
                      iconName: 'Play',
                    }}
                    text="Run"
                    styles={isActionDisabled ? runButtonStylesDisabled : runButtonStyles}
                    disabled={isActionDisabled}
                    onClick={() => {
                      if (isSaved) {
                        void runWorkflow();
                      } else {
                        addToast({
                          messageBarType: MessageBarType.warning,
                          message: `Please save the workflow before running.`,
                        });
                      }
                    }}
                  />
                )}
              </div>
            ),
            styles: {
              root: {
                justifyContent: 'flex-end',
                flexGrow: 1,
                '.h2o-ListCell-content': {
                  padding: '0px 12px',
                },
              },
            },
          },
        },
      },
    ];

  return (
    <div className={classNames.row}>
      <ListRow
        columns={rowColumns}
        data={rowData}
        styles={widgetStyles}
        collapsed={!widgetExpanded}
        hasOpenClose
        openCloseByButton
        onOpen={handleWidgetOpen}
        contents={
          <>
            <h4 className={classNames.widgetContentTitle}>Advanced configuration</h4>
            <div className={classNames.widgetExpandedContent}>
              <div className={classNames.widgetExpandedContentItem}>
                <Info isTooltip className={classNames.tooltip}>
                  When set to 0 server will not limit concurrent executions.
                </Info>
                <TextField
                  label="Concurrency limit"
                  value={concurrencyLimit.toString()}
                  onChange={onChangeConcurrencyLimit}
                  type="number"
                  styles={{ field: { paddingRight: 0 } }}
                  readOnly={readOnly}
                />
              </div>
              <div className={classNames.widgetExpandedContentItem}>
                <Info isTooltip className={classNames.tooltip}>
                  When specified, the workflow will be cancelled after this time.
                </Info>
                <TextField
                  label="Timeout (s)"
                  value={timeout || ''}
                  onChange={onChangeTimeout}
                  type="number"
                  styles={{ field: { paddingRight: 0 } }}
                  readOnly={readOnly}
                />
              </div>
            </div>
          </>
        }
      />
    </div>
  );
};

export default WorkflowCanvasWidget;
