import { type IColumn } from '@fluentui/react';

import { EntityActionType, EntityFieldType, EntityType } from '../../components/AdminSettings/Entity/constants';
import type { Entity, EntityAction, EntityField } from '../../components/AdminSettings/Entity/types';
import {
  getOptionsFromEnum,
  onRenderDisplayName,
  onRenderKernelImageType,
} from '../../components/AdminSettings/Entity/utils';
import { onRenderBooleanCell, onRenderDataCell } from '../../utils/render';
import { ImagePullPolicy } from '../gen/ai/h2o/notebook/v1/base_types_pb';
import { type KernelImage, KernelImage_Type } from '../gen/ai/h2o/notebook/v1/kernel_image_pb';
import {
  type CreateKernelImageRequest,
  type CreateKernelImageResponse,
  type DeleteKernelImageRequest,
  type DeleteKernelImageResponse,
  type GetKernelImageRequest,
  type GetKernelImageResponse,
  KernelImageService_CreateKernelImage,
  KernelImageService_DeleteKernelImage,
  KernelImageService_GetKernelImage,
  KernelImageService_ListKernelImages,
  KernelImageService_UpdateKernelImage,
  type ListKernelImagesRequest,
  type ListKernelImagesResponse,
  type UpdateKernelImageRequest,
  type UpdateKernelImageResponse,
} from '../gen/ai/h2o/notebook/v1/kernel_image_service_pb';
import { kernelImagesResponseMock } from './mocks/responses.mock';

const entityType = EntityType.KernelImage;
const required = true;

export const actions = {
  [EntityActionType.Create]: {
    name: 'CreateKernelImage',
    rpc: KernelImageService_CreateKernelImage,
    type: EntityActionType.Create,
    mockResponse: kernelImagesResponseMock[0],
    responseKey: 'kernelImage',
    requestNameKey: 'kernelImageId',
    requestPayloadKey: 'kernelImage',
    requestNameKeyPrefix: 'kernelImage/',
    mockPath: '/kernelImage',
    entityType,
  } as EntityAction<KernelImage, CreateKernelImageRequest, CreateKernelImageResponse>,
  [EntityActionType.Get]: {
    name: 'GetKernelImage',
    rpc: KernelImageService_GetKernelImage,
    type: EntityActionType.Get,
    responseKey: 'kernelImage',
    mockPath: '/kernelImage',
    mockResponse: kernelImagesResponseMock[0],
    entityType,
  } as EntityAction<KernelImage, GetKernelImageRequest, GetKernelImageResponse>,
  [EntityActionType.List]: {
    name: 'ListKernelImage',
    rpc: KernelImageService_ListKernelImages,
    type: EntityActionType.List,
    mockResponse: kernelImagesResponseMock,
    responseKey: 'kernelImages',
    mockPath: '/kernelImages',
    entityType,
  } as EntityAction<KernelImage, ListKernelImagesRequest, ListKernelImagesResponse>,
  [EntityActionType.Update]: {
    name: 'UpdateKernelImage',
    rpc: KernelImageService_UpdateKernelImage,
    type: EntityActionType.Update,
    mockPath: '/kernelImage',
    mockResponse: kernelImagesResponseMock[0],
    entityType,
    responseKey: 'kernelImage',
    requestNameKey: 'name' as unknown,
    requestPayloadKey: 'kernelImage',
  } as EntityAction<KernelImage, UpdateKernelImageRequest, UpdateKernelImageResponse>,
  [EntityActionType.Delete]: {
    name: 'DeleteKernelImage',
    rpc: KernelImageService_DeleteKernelImage,
    type: EntityActionType.Delete,
    entityType,
  } as EntityAction<KernelImage, DeleteKernelImageRequest, DeleteKernelImageResponse>,
};

export const columns: IColumn[] = [
  {
    key: 'name',
    name: 'Name',
    fieldName: 'displayName',
    minWidth: 200,
    maxWidth: 350,
    onRender: onRenderDisplayName,
  },
  {
    key: 'type',
    name: 'Type',
    fieldName: 'type',
    minWidth: 90,
    maxWidth: 120,
    onRender: onRenderKernelImageType,
  },
  {
    key: 'disabled',
    name: 'Disabled',
    fieldName: 'disabled',
    minWidth: 80,
    maxWidth: 120,
    onRender: onRenderBooleanCell,
  },
  {
    key: 'image',
    name: 'Image',
    fieldName: 'image',
    minWidth: 350,
    onRender: onRenderDataCell,
  },
  {
    key: 'actions',
    name: 'Actions',
    fieldName: 'actions',
    minWidth: 170,
    maxWidth: 170,
  },
];

export const fields: EntityField<KernelImage>[] = [
  {
    name: 'name',
    type: EntityFieldType.IdOnDisplayAndId,
    label: 'Name',
    description: 'Kernel Image resource name.',
    required,
  },
  {
    name: 'displayName',
    type: EntityFieldType.DisplayOnDisplayAndId,
    label: 'Display Name',
    description: 'Human-readable name of Kernel Image',
    required,
  },
  {
    name: 'type',
    type: EntityFieldType.SelectEnum,
    options: getOptionsFromEnum(KernelImage_Type, 'TYPE_', [KernelImage_Type.UNSPECIFIED]),
    label: 'Type',
  },
  {
    name: 'image',
    type: EntityFieldType.Text,
    label: 'Image',
    required,
  },
  {
    name: 'disabled',
    type: EntityFieldType.Boolean,
    label: 'Disabled',
  },
  {
    name: 'imagePullPolicy',
    type: EntityFieldType.SelectEnum,
    label: 'Image pull policy',
    options: getOptionsFromEnum(ImagePullPolicy, 'IMAGE_PULL_POLICY_', [ImagePullPolicy.UNSPECIFIED]),
  },
  {
    name: 'imagePullSecrets',
    type: EntityFieldType.StringArray,
    label: 'Image pull secrets',
  },
];

export const kernelImageEntity: Entity<KernelImage> = {
  type: entityType,
  name: 'KernelImage',
  createButtonLabel: '',
  displayName: 'Kernel Image',
  displayNameKey: 'displayName',
  requestNameKeyPrefix: 'workspaces/global/kernelImages/',
  actions,
  columns,
  fields,
  emptyModel: {
    name: 'new-kernel-image',
    displayName: 'New Kernel Image',
    type: KernelImage_Type.PYTHON,
    image: '',
    disabled: false,
    imagePullPolicy: ImagePullPolicy.IF_NOT_PRESENT,
    imagePullSecrets: [],
  },
  canBeReordered: false,
};
