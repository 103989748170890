import { MessageBarType } from '@fluentui/react';
import { useToast } from '@h2oai/ui-kit';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { FailedToLoadView } from '../../components/FailedToLoadView/FailedToLoadView';
import { NoItemView } from '../../components/NoItemView/NoItemView';
import WidgetList from '../../components/WidgetList/WidgetList';
import { useOrchestratorService } from '../../orchestrator/hooks';
import { formatError } from '../../utils/utils';
import { useRoles } from './RoleProvider';
import { WorkflowFixed } from './WorkflowTabCanvas';
import { WorkflowExecutionItem, calculateDuration, executionColumns } from './WorkflowTabExecutions';
import { useWorkspaces } from './WorkspaceProvider';

type Props = {
  filterBy?: string;
  noItemsViewShadowed?: boolean;
};

const getFilter = (filterBy?: string) => {
  // TODO: Add support for STATE_PENDING, STATE_UNSPECIFIED, STATE_BLOCKING, STATE_UPSTREAM_FAILED once stats available from service.
  switch (filterBy) {
    case 'running':
      return "state='STATE_RUNNING'";
    case 'finished':
      return "state='STATE_FINISHED'";
    case 'failed':
      return "state='STATE_FAILED'";
    case 'cancelled':
      return "state='STATE_CANCELLED'";
    default:
      return undefined;
  }
};

export const extractWorkflowName = (workflowExecution?: string) =>
  workflowExecution?.includes('/executions/') ? workflowExecution.split('/executions/')?.[0] : undefined;

const Executions = ({ filterBy, noItemsViewShadowed = true }: Props) => {
  const history = useHistory(),
    orchestratorService = useOrchestratorService(),
    { addToast } = useToast(),
    { ACTIVE_WORKSPACE_NAME } = useWorkspaces(),
    { permissions } = useRoles(),
    [loading, setLoading] = React.useState(true),
    [isLoadingMore, setIsLoadingMore] = React.useState(false),
    [nextPageToken, setNextPageToken] = React.useState<string>(),
    [executionsItems, setExecutionsItems] = React.useState<WorkflowExecutionItem[]>(),
    [workflows, setWorkflows] = React.useState<WorkflowFixed[]>(),
    [lastRefreshed, setLastRefreshed] = React.useState<Date>(),
    [wrapperHeight, setWrapperHeight] = React.useState<string>(),
    wrapperRef = React.useRef<HTMLDivElement>(null),
    intervalRef = React.useRef<NodeJS.Timeout>(),
    loadStateRef = React.useRef({
      fetchExecutions: false,
      fetchWorkflows: false,
    }),
    evaluateLoading = () => {
      if (!loadStateRef.current.fetchExecutions && !loadStateRef.current.fetchWorkflows) {
        setLoading(false);
      }
    },
    onManualRefresh = () => {
      // Prevent layout jump when height changes during loader being shown.
      setWrapperHeight(`${wrapperRef.current?.clientHeight}px`);
      void fetchExecutions();
    },
    deleteExecution = async (executionName: string) => {
      try {
        await orchestratorService.deleteWorkflowExecution({ name: executionName });
        addToast({
          messageBarType: MessageBarType.success,
          message: 'Execution deleted successfully',
        });
        void fetchExecutions();
      } catch (err) {
        const message = `Failed to delete execution: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      }
    },
    fetchExecutions = React.useCallback(
      async (pageToken?: string) => {
        if (pageToken) {
          setIsLoadingMore(true);
        } else {
          loadStateRef.current.fetchExecutions = true;
          setLoading(true);
        }
        try {
          const data = await orchestratorService.getWorkflowExecutions({
            parent: ACTIVE_WORKSPACE_NAME || '',
            filter: getFilter(filterBy),
            orderBy: 'start_time desc',
            pageSize: 20,
            pageToken,
          });
          const newExecutionsItems = data?.workflowExecutions
            ? data.workflowExecutions.map((item) => ({
                ...item,
                workflowDisplayName:
                  workflows?.find((w) => w.name === extractWorkflowName(item.name))?.displayName ||
                  extractWorkflowName(item.name) ||
                  item.name,
                startTimeLocal: item.startTime ? new Date(item.startTime).toLocaleString() : undefined,
                endTimeLocal: item.endTime ? new Date(item.endTime).toLocaleString() : undefined,
                runBy: item.initiator
                  ? `${item.initiator.includes('/workflows') ? 'Triggered' : 'Manually run'} by ${
                      item.initiatorDisplayName || item.initiator
                    }`
                  : '(Unknown)',
                duration: calculateDuration(item.startTime, item.endTime),
                onView: () => history.push(`/orchestrator/${item.name}`),
                onCancel: () => void cancelExecution(item.name || ''),
                onDelete: () => void deleteExecution(item.name || ''),
                viewOnly: !permissions.canRunWorkflows,
              }))
            : undefined;
          if (data && !newExecutionsItems) console.error('No executions found in the response.');
          setNextPageToken(data?.nextPageToken || undefined);
          setExecutionsItems((items) =>
            pageToken ? [...(items || []), ...(newExecutionsItems || [])] : newExecutionsItems
          );
        } catch (err) {
          const message = `Failed to fetch workflow executions: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          setExecutionsItems(undefined);
        } finally {
          loadStateRef.current.fetchExecutions = false;
          evaluateLoading();
          setIsLoadingMore(false);
          setLastRefreshed(new Date());
        }
      },
      [ACTIVE_WORKSPACE_NAME, addToast, history, orchestratorService, workflows, permissions]
    ),
    cancelExecution = React.useCallback(
      async (executionName: string) => {
        try {
          await orchestratorService.cancelWorkflowExecution({ name: executionName });
          addToast({
            messageBarType: MessageBarType.success,
            message: 'Execution cancelled successfully',
          });
          void fetchExecutions();
        } catch (err) {
          const message = `Failed to cancel execution: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        }
      },
      [addToast, fetchExecutions, orchestratorService]
    ),
    fetchWorkflows = React.useCallback(async () => {
      loadStateRef.current.fetchWorkflows = true;
      setLoading(true);
      try {
        const data = await orchestratorService.getWorkflows({ parent: ACTIVE_WORKSPACE_NAME || '' });
        setWorkflows(data?.workflows);
        setExecutionsItems((items) =>
          items?.map((item) => ({
            ...item,
            workflowDisplayName:
              data?.workflows?.find((w) => w.name === extractWorkflowName(item.name))?.displayName ||
              extractWorkflowName(item.name) ||
              '(Unknown)',
          }))
        );
      } catch (err) {
        const message = `Failed to fetch workflows: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setWorkflows(undefined);
      } finally {
        loadStateRef.current.fetchWorkflows = false;
        evaluateLoading();
      }
    }, [ACTIVE_WORKSPACE_NAME, addToast, orchestratorService]);

  React.useEffect(() => {
    if (ACTIVE_WORKSPACE_NAME) {
      void fetchWorkflows();
      void fetchExecutions();
      // TODO: Cleanup running requests on unmount.
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ACTIVE_WORKSPACE_NAME, filterBy]);

  React.useEffect(() => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      if (ACTIVE_WORKSPACE_NAME) {
        // Prevent layout jump when height changes during loader being shown.
        setWrapperHeight(`${wrapperRef.current?.clientHeight}px`);
        void fetchExecutions();
      }
    }, 15000);

    return () => clearInterval(intervalRef.current);
  }, [ACTIVE_WORKSPACE_NAME, filterBy]);

  React.useEffect(() => setWrapperHeight(undefined), [executionsItems]);

  React.useEffect(() => {
    setExecutionsItems((items) =>
      items?.[0]?.viewOnly === !permissions.canRunWorkflows
        ? items
        : items?.map((item) => ({
            ...item,
            viewOnly: !permissions.canRunWorkflows,
          }))
    );
  }, [permissions]);

  return (
    <div ref={wrapperRef} style={{ height: wrapperHeight }}>
      <WidgetList
        columns={executionColumns}
        items={executionsItems}
        loading={loading}
        isLoadingMore={isLoadingMore}
        onLoadMore={nextPageToken ? () => void fetchExecutions(nextPageToken) : undefined}
        lastRefreshed={lastRefreshed?.toLocaleString()}
        onRefresh={onManualRefresh}
        NoItemsContent={NoItemView({
          title: 'No executions',
          description: filterBy
            ? `There are currently no ${filterBy} workflows in this workspace.`
            : 'There are currently no workflow executions to display.',
          shadowed: noItemsViewShadowed,
          backgroundImage: `url(${require('./assets/play.png')})`,
          isBackgroundPattern: true,
        })}
        ErrorContent={FailedToLoadView({
          title: 'Failed to load workflow executions',
          description: 'Please try again later. If the problem persists, contact our support.',
          actionTitle: 'Retry',
          onActionClick: fetchExecutions,
          actionIcon: 'Refresh',
        })}
      />
    </div>
  );
};

export default Executions;
