import { IChoiceGroupOption, Label, MessageBarType, Toggle } from '@fluentui/react';
import {
  Button,
  ChoiceGroup,
  ClockFormat,
  Cron,
  CronError,
  Dropdown,
  KeyValuePairEditor,
  TextField,
  buttonStylesIcon,
  buttonStylesPrimary,
  choiceGroupStylesHorizontalBox,
  useClassNames,
  useTheme,
  useToast,
} from '@h2oai/ui-kit';
import cronstrue from 'cronstrue';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { FailedToLoadView } from '../../components/FailedToLoadView/FailedToLoadView';
import { NoItemView } from '../../components/NoItemView/NoItemView';
import { RowHeaderTitle } from '../../components/RowHeaderTitle/RowHeaderTitle';
import WidgetList from '../../components/WidgetList/WidgetList';
import {
  WorkflowTrigger,
  WorkflowTrigger_State,
} from '../../orchestrator/gen/ai/h2o/orchestrator/v1/workflow_trigger_pb';
import { useOrchestratorService } from '../../orchestrator/hooks';
import { ClassNamesFromIStyles } from '../../utils/models';
import { formatError } from '../../utils/utils';
import { WORKFLOW_PARAMS_SUPPORTED } from './constants';
import { CronConverterU2Q as c2q, getQuartz } from './cronUtils';
import { useRoles } from './RoleProvider';
import SearchableDropdown from './SearchableDropdown';
import { ContextMenuIconButton } from './Workflows';
import { WorkflowFixed } from './WorkflowTabCanvas';
import { IWorkflowTabTriggersStyles, workflowTabTriggersStyles } from './WorkflowTabTriggers.styles';

type TriggerItem = WorkflowTrigger & {
  onView: () => void;
  onDelete: () => void;
  onEdit: () => void;
  onActivate: () => void;
  onDeactivate: () => void;
  viewOnly: boolean;
  createTimeLocal?: string;
};

export type ExecutionNavParams = { workflow_id: string; workspace_id: string; tab_id?: string; item_name?: string };

interface IWorkflowTabTriggersProps {
  workflow?: WorkflowFixed;
}

const triggerColumns = [
    {
      key: 'name',
      name: 'Title',
      fieldName: 'name',
      minWidth: 180,
      maxWidth: 360,
      data: {
        headerFieldName: 'displayName',
        listCellProps: {
          onRenderHeader: ({ displayName, onView }: TriggerItem) =>
            RowHeaderTitle({ title: displayName, onClick: onView }),
          iconProps: {
            iconName: 'TriggerAuto',
          },
        },
      },
    },
    {
      key: 'status',
      name: 'Is active',
      fieldName: 'status',
      minWidth: 90,
      maxWidth: 130,
      data: {
        listCellProps: {
          // TODO: Use theme colors.
          onRenderText: ({ state, onActivate, onDeactivate }: TriggerItem) => (
            // TODO: Add support for UNSPECIFIED state.
            <Toggle
              inlineLabel
              onClick={state === WorkflowTrigger_State.ACTIVE ? onDeactivate : onActivate}
              checked={state === WorkflowTrigger_State.ACTIVE}
              styles={{
                pill: {
                  backgroundColor: state === WorkflowTrigger_State.ACTIVE ? '#E9FFE6' : '#FFD9D9',
                  borderColor: state === WorkflowTrigger_State.ACTIVE ? '#E9FFE6' : '#FFD9D9',
                  '&[aria-checked=true]': {
                    backgroundColor: '#E9FFE6',
                  },
                },
                thumb: {
                  backgroundColor: state === WorkflowTrigger_State.ACTIVE ? '#00A300 !important' : '#D13438 !important',
                },
              }}
            />
          ),
        },
      },
    },
    {
      key: 'scheduledAt',
      name: 'Scheduled at',
      fieldName: 'scheduledAt',
      minWidth: 90,
      maxWidth: 180,
      data: {
        listCellProps: {
          // TODO: Use theme colors.
          onRenderText: ({ schedule }: TriggerItem) => (
            <span style={{ color: 'var(--h2o-gray500, #6B7280)', fontSize: 12 }}>
              {schedule?.cronExpression
                ? cronstrue.toString(schedule.cronExpression, { throwExceptionOnParseError: false }) ||
                  schedule.cronExpression
                : ''}
            </span>
          ),
        },
      },
    },
    {
      key: 'creator',
      name: 'Creator',
      fieldName: 'creator',
      minWidth: 90,
      maxWidth: 180,
    },
    {
      key: 'createdAt',
      name: 'Created at',
      fieldName: 'createTimeLocal',
      minWidth: 90,
      maxWidth: 180,
    },
    {
      key: 'buttons',
      name: '',
      minWidth: 140,
      maxWidth: 200,
      data: {
        listCellProps: {
          emptyMessage: 'No Description',
          onRenderText: ({ onDeactivate, onDelete, onActivate, onView, onEdit, state, viewOnly }: TriggerItem) => (
            // TODO: Use theme prop for colors.
            // TODO: Handle UNSPECIFIED state.
            <ContextMenuIconButton
              items={[
                {
                  key: 'activate',
                  text: 'Activate',
                  onClick: onActivate,
                  style: {
                    color: 'var(--h2o-yellow700)',
                    display: state === WorkflowTrigger_State.INACTIVE && !viewOnly ? undefined : 'none',
                  },
                  iconProps: { iconName: 'Play', style: { color: 'var(--h2o-yellow700)' } },
                },
                {
                  key: 'deactivate',
                  text: 'Deactivate',
                  onClick: onDeactivate,
                  style: {
                    color: 'var(--h2o-yellow700)',
                    display: state === WorkflowTrigger_State.ACTIVE && !viewOnly ? undefined : 'none',
                  },
                  iconProps: { iconName: 'CircleStop', style: { color: 'var(--h2o-yellow700)' } },
                },
                {
                  key: viewOnly ? 'view' : 'edit',
                  text: viewOnly ? 'View' : 'Edit',
                  onClick: viewOnly ? onView : onEdit,
                  iconProps: { iconName: viewOnly ? 'RedEye' : 'Edit', style: { color: 'var(--h2o-gray900)' } },
                },
                {
                  key: 'delete',
                  text: 'Delete',
                  onClick: onDelete,
                  style: {
                    color: 'var(--h2o-red400)',
                    display: viewOnly ? 'none' : undefined,
                  },
                  iconProps: { iconName: 'Delete', style: { color: 'var(--h2o-red400)' } },
                },
              ]}
            />
          ),
          styles: {
            root: {
              display: 'flex',
              flexGrow: 1,
              justifyContent: 'end',
            },
          },
        },
      },
    },
  ],
  clockOptions = [
    {
      key: '12-hour-clock',
      text: '12-hour clock',
    },
    {
      key: '24-hour-clock',
      text: '24-hour clock',
    },
  ],
  triggerTypeOptions = [
    { key: 'schedule', text: 'Schedule' },
    { key: 'event', text: 'Event (coming soon)', disabled: true },
    { key: 'api', text: 'API (coming soon)', disabled: true },
  ],
  // TODO: Remove disabling rules when TypeScript is updated. Updated types for Intl will be shipped with TypeScript 5.1 - https://github.com/microsoft/TypeScript/issues/49231
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Property 'supportedValuesOf' does not exist on type 'typeof Intl'.
  supportedValuesOf = Intl?.supportedValuesOf,
  timeZoneOptions = !supportedValuesOf
    ? [
        {
          key: 'notSupported',
          text: 'Your browser does not support Intl.supportedValuesOf().',
          disabled: true,
        },
      ]
    : supportedValuesOf('timeZone').map((tz: string) => ({ key: tz, text: tz }));

const WorkflowTabTriggers = ({ workflow }: IWorkflowTabTriggersProps) => {
  const theme = useTheme(),
    classNames = useClassNames<IWorkflowTabTriggersStyles, ClassNamesFromIStyles<IWorkflowTabTriggersStyles>>(
      'workflowTabTriggers',
      workflowTabTriggersStyles(theme)
    ),
    params = useParams<ExecutionNavParams>(),
    history = useHistory(),
    { permissions } = useRoles(),
    { addToast } = useToast(),
    [inputValue, setInputValue] = React.useState(''),
    [cronValue, setCronValue] = React.useState(''),
    [displayName, setDisplayName] = React.useState(''),
    [config, setConfig] = React.useState({}),
    orchestratorService = useOrchestratorService(),
    [cronError, onCronError] = React.useState<CronError>(),
    [triggerItems, setTriggerItems] = React.useState<TriggerItem[] | undefined>(),
    [triggerDetail, setTriggerDetail] = React.useState<TriggerItem | undefined>(),
    [showValidation, setShowValidation] = React.useState(false),
    [timeZone, setTimeZone] = React.useState(
      triggerDetail?.schedule?.timeZone || Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || ''
    ),
    [clockFormat, setClockFormat] = React.useState<ClockFormat>('12-hour-clock'),
    [isLoadingMore, setIsLoadingMore] = React.useState(false),
    [isLoadingSearch, setIsLoadingSearch] = React.useState(false),
    [nextPageToken, setNextPageToken] = React.useState<string>(),
    [isActionDisabled, setIsActionDisabled] = React.useState(false),
    [loading, setLoading] = React.useState(true),
    onUpdateConfig = (config: Record<string, string>) => setConfig(config),
    fetchTriggers = React.useCallback(
      async (pageToken?: string, filter?: string) => {
        if (pageToken) {
          setIsLoadingMore(true);
        } else if (filter || filter === `display_name = ""`) {
          setIsLoadingSearch(true);
        } else {
          setLoading(true);
        }
        try {
          const data = await orchestratorService.getWorkflowTriggers({
            parent: workflow?.name || '',
            pageSize: 20,
            pageToken,
            orderBy: 'create_time desc',
            filter: filter === `display_name = ""` ? undefined : filter,
          });
          const newTriggerItems = data?.workflowTriggers
            ? data.workflowTriggers.map((item) => ({
                ...item,
                onView: () => history.push(`/orchestrator/${item.name}`),
                onDelete: () => deleteTrigger(item.name || '', item.displayName || ''),
                onActivate: () => activateTrigger(item.name || '', item.displayName || ''),
                onDeactivate: () => deactivateTrigger(item.name || '', item.displayName || ''),
                onEdit: () => history.push(`/orchestrator/${item.name}`),
                viewOnly: !permissions.canRunWorkflows,
                createTimeLocal: item.createTime ? new Date(item.createTime).toLocaleString() : undefined,
                scheduledAt: item.schedule?.cronExpression || '',
              }))
            : undefined;
          if (data && !newTriggerItems) console.error('No triggers found in the response.');
          setNextPageToken(data?.nextPageToken || undefined);
          setTriggerItems((items) => (pageToken ? [...(items || []), ...(newTriggerItems || [])] : newTriggerItems));
        } catch (err) {
          const message = `Failed to fetch workflow triggers: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        } finally {
          setLoading(false);
          setIsLoadingMore(false);
          setIsLoadingSearch(false);
        }
      },
      [workflow?.name, permissions]
    ),
    createTrigger = React.useCallback(async () => {
      setIsActionDisabled(true);
      try {
        await orchestratorService.createWorkflowTrigger({
          parent: workflow?.name || '',
          workflowTrigger: {
            displayName,
            schedule: {
              // Convert to quartz cron expression. Backend uses quartz cron format.
              cronExpression: getQuartz(cronValue)[0].join(' '),
              timeZone,
            },
            parameters: config,
            state: WorkflowTrigger_State.ACTIVE,
          },
        });
        addToast({
          messageBarType: MessageBarType.success,
          message: `Trigger ${displayName} created.`,
        });
        fetchTriggers();
        history.push(`/orchestrator/${workflow?.name}/triggers`);
      } catch (err) {
        const message = `Failed to create trigger: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      } finally {
        setIsActionDisabled(false);
      }
    }, [workflow?.name, displayName, cronValue, timeZone, config, fetchTriggers]),
    updateTrigger = React.useCallback(async () => {
      setIsActionDisabled(true);
      try {
        await orchestratorService.updateWorkflowTrigger({
          updateMask: 'displayName,schedule,parameters',
          workflowTrigger: {
            name: triggerDetail?.name || '',
            displayName,
            schedule: {
              // Convert to quartz cron expression. Backend uses quartz cron format.
              cronExpression: getQuartz(cronValue)[0].join(' '),
              timeZone,
            },
            parameters: config,
            state: triggerDetail?.state || WorkflowTrigger_State.ACTIVE,
          },
        });
        addToast({
          messageBarType: MessageBarType.success,
          message: `Trigger ${displayName} updated.`,
        });
        fetchTriggers();
        history.push(`/orchestrator/${workflow?.name}/triggers`);
      } catch (err) {
        const message = `Failed to update trigger: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      } finally {
        setIsActionDisabled(false);
      }
    }, [
      workflow?.name,
      displayName,
      cronValue,
      timeZone,
      config,
      triggerDetail?.name,
      triggerDetail?.state,
      fetchTriggers,
    ]),
    setTriggerActivation = React.useCallback(
      async (name: string, displayName: string, active: boolean) => {
        try {
          if (active) {
            await orchestratorService.resumeWorkflowTrigger({ name });
          } else {
            await orchestratorService.pauseWorkflowTrigger({ name });
          }

          addToast({
            messageBarType: MessageBarType.success,
            message: `Trigger ${displayName} ${active ? 'activated' : 'deactivated'}.`,
          });
          void fetchTriggers();
        } catch (err) {
          const message = `Failed to ${active ? 'activate' : 'deactivate'} trigger: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        }
      },
      [fetchTriggers]
    ),
    activateTrigger = (name: string, displayName: string) => setTriggerActivation(name, displayName, true),
    deactivateTrigger = (name: string, displayName: string) => setTriggerActivation(name, displayName, false),
    deleteTrigger = React.useCallback(
      async (name: string, displayName: string) => {
        try {
          await orchestratorService.deleteWorkflowTrigger({
            name,
          });
          addToast({
            messageBarType: MessageBarType.success,
            message: `Trigger ${displayName} deleted.`,
          });
          void fetchTriggers();
        } catch (err) {
          const message = `Failed to delete trigger: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        }
      },
      [fetchTriggers]
    ),
    onSave = React.useCallback(() => {
      if (!displayName || !cronValue || cronError || !timeZone || getQuartz(cronValue).length > 1) {
        addToast({
          messageBarType: MessageBarType.error,
          message: cronError ?? 'Please fill in all required fields.',
        });
        if (cronError) console.error('CRON validation failed:', cronError);
        setShowValidation(true);
        return;
      }
      setShowValidation(false);
      if (triggerDetail?.name) {
        void updateTrigger();
      } else {
        void createTrigger();
      }
    }, [triggerDetail?.name, displayName, cronValue, cronError, updateTrigger, createTrigger]),
    onDisplayNameChange = (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      setDisplayName(newValue || '');
    },
    onCronExpressionChange = (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      setInputValue(newValue || '');
    },
    onCronExpressionKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        setCronValue(inputValue);
      }
    },
    onTimeZoneChange = (item: { key: string; text?: string }) => {
      if (item) setTimeZone(item.text as string);
    },
    onClockOptionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
      if (option) setClockFormat(option.key as ClockFormat);
    },
    keyValuePairEditorStyles = React.useMemo(
      () => ({
        root: {
          backgroundColor: theme.semanticColors?.contentBackground,
          maxWidth: 460,
          border: `1px solid ${theme.semanticColors?.inputBorder}`,
          borderRadius: 4,
        },
      }),
      [theme.semanticColors?.contentBackground, theme.semanticColors?.inputBorder, triggerDetail?.viewOnly]
    ),
    cronExpressionInputStyles = {
      root: {
        marginBottom: 8,
        maxWidth: 360,
      },
    };

  React.useEffect(() => {
    if (triggerItems) {
      const item = triggerItems?.find((item) => item.name === `${workflow?.name}/triggers/${params.item_name}`);
      if (item) {
        setTriggerDetail(item);
      } else {
        setTriggerDetail(undefined);
      }
    }
    if (params.item_name === 'create-new') setTriggerDetail({} as TriggerItem);
  }, [params.item_name, triggerItems]);

  React.useEffect(() => {
    if (workflow?.name) void fetchTriggers();
  }, [fetchTriggers, workflow?.name]);

  React.useEffect(() => {
    // Convert to unix cron expression. Frontend uses unix cron format (`* * * * *`). It doesn't use seconds and years.
    const cronExpression = triggerDetail?.schedule?.cronExpression
      ? c2q.quartzToUnix(triggerDetail?.schedule?.cronExpression)
      : '';
    setDisplayName(triggerDetail?.displayName || '');
    setInputValue(cronExpression || '');
    // TODO: Show error for invalid cron expressions.
    setCronValue(cronExpression || '');
    setConfig(triggerDetail?.parameters || {});
  }, [triggerDetail]);

  return (
    <>
      {triggerDetail ? (
        <div className={classNames.triggerDetailContainer}>
          <Button
            iconName="Back"
            styles={[buttonStylesIcon]}
            onClick={() => {
              setTriggerDetail(undefined);
              history.push(`/orchestrator/${workflow?.name}/triggers`);
            }}
            text="Back to all triggers"
          />
          <div className={classNames.cronContainer}>
            <h3>{`${triggerDetail.viewOnly ? 'View' : triggerDetail.name ? 'Update' : 'Create new'} trigger`}</h3>
            <TextField
              value={displayName}
              label="Name"
              required
              styles={{ root: { maxWidth: 360, marginBottom: 16 } }}
              readOnly={triggerDetail.viewOnly}
              onChange={onDisplayNameChange}
              errorMessage={showValidation && !displayName ? 'Name is required' : undefined}
            />
            <Dropdown
              label="Trigger type"
              required
              selectedKey="schedule"
              disabled={triggerDetail.viewOnly}
              // TODO: Add support for event and api triggers.
              options={triggerTypeOptions}
              styles={{ root: { maxWidth: 360, marginBottom: 16 } }}
              errorMessage={
                showValidation
                  ? cronError
                    ? 'Invalid cron expression'
                    : !inputValue
                    ? 'Cron expression is required'
                    : ''
                  : undefined
              }
            />
            <SearchableDropdown
              required
              label="Time zone"
              disabled={triggerDetail.viewOnly}
              value={timeZone}
              options={timeZoneOptions}
              placeHolder="Select a timezone"
              searchBoxPlaceHolder="Search for a timezone"
              onChange={onTimeZoneChange}
              width={360}
              maxHeight={250}
              style={{ marginBottom: 16 }}
              errorMessage={showValidation && !timeZone ? 'Time zone is required' : undefined}
            />
            <TextField
              value={inputValue}
              label="Cron expression"
              required
              readOnly={triggerDetail.viewOnly}
              styles={cronExpressionInputStyles}
              onChange={onCronExpressionChange}
              onBlur={() => setCronValue(inputValue)}
              onKeyDown={onCronExpressionKeyDown}
              errorMessage={
                showValidation && getQuartz(cronValue).length > 1
                  ? 'Cannot specify both Day-Of-Month and Day-Of-Week simultaneously.'
                  : cronError?.description
              }
            />
            <ChoiceGroup
              options={clockOptions}
              styles={choiceGroupStylesHorizontalBox}
              style={{ maxWidth: 360, marginBottom: 16, display: triggerDetail.viewOnly ? 'none' : undefined }}
              selectedKey={clockFormat}
              onChange={onClockOptionsChange}
            />
            <Cron
              value={cronValue}
              setValue={(newValue: string) => {
                setCronValue(newValue);
                setInputValue(newValue);
              }}
              readOnly={triggerDetail.viewOnly}
              clockFormat={clockFormat}
              onError={onCronError}
            />
            <p className={classNames.cronError}>{cronError ? `Error: ${cronError.description}` : null}</p>
            {WORKFLOW_PARAMS_SUPPORTED ? (
              <>
                <Label>Parameters</Label>
                <KeyValuePairEditor
                  config={config}
                  onUpdateConfig={onUpdateConfig}
                  styles={keyValuePairEditorStyles}
                  readOnly={triggerDetail?.viewOnly}
                />
              </>
            ) : null}
          </div>
          {triggerDetail.viewOnly ? null : (
            <Button
              className={classNames.saveButton}
              text="Save changes"
              onClick={onSave}
              styles={buttonStylesPrimary}
              disabled={isActionDisabled}
            />
          )}
        </div>
      ) : params.workflow_id !== 'create-new' ? (
        <WidgetList
          columns={triggerColumns}
          items={triggerItems}
          loading={loading}
          isLoadingMore={isLoadingMore}
          isLoadingSearch={isLoadingSearch}
          onLoadMore={nextPageToken ? () => void fetchTriggers(nextPageToken) : undefined}
          searchProps={{
            placeholder: 'Search triggers',
            onSearchChange: (value) => {
              void fetchTriggers(undefined, `display_name = '${value}'`);
            },
          }}
          NoItemsContent={NoItemView({
            title: 'Workflow triggers',
            description: `There are no triggers specified for this workflow. ${
              permissions.canRunWorkflows ? 'Create the first trigger.' : ''
            }`,
            actionTitle: permissions.canRunWorkflows ? 'Create trigger' : undefined,
            onActionClick: () => history.push(`/orchestrator/${workflow?.name}/triggers/create-new`),
            actionIcon: 'Add',
            // TODO: Add image.
            // backgroundImage: `url(${require('./assets/no-triggers-background.gif')})`,
          })}
          ErrorContent={FailedToLoadView({
            title: 'Failed to load triggers',
            description: 'Please try again later. If the problem persists, contact our support.',
            actionTitle: 'Retry',
            onActionClick: fetchTriggers,
            actionIcon: 'Refresh',
          })}
        />
      ) : (
        NoItemView({
          title: 'Save the workflow first',
          description: 'Please return to the workflow detail tab and save the workflow before creating triggers.',
        })
      )}
    </>
  );
};

export default WorkflowTabTriggers;
