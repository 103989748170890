import { type IColumn } from '@fluentui/react';

import { EntityActionType, EntityFieldType, EntityType } from '../../components/AdminSettings/Entity/constants';
import type { Entity, EntityAction, EntityField } from '../../components/AdminSettings/Entity/types';
import { getResourceId, onRenderDisplayName } from '../../components/AdminSettings/Entity/utils';
import { onRenderBooleanCell, onRenderDataCell } from '../../utils/render';
import { type NotebookKernelSpec } from '../gen/ai/h2o/notebook/v1/notebook_kernel_spec_pb';
import {
  type CreateNotebookKernelSpecRequest,
  type CreateNotebookKernelSpecResponse,
  type DeleteNotebookKernelSpecRequest,
  type DeleteNotebookKernelSpecResponse,
  type GetNotebookKernelSpecRequest,
  type GetNotebookKernelSpecResponse,
  type ListNotebookKernelSpecsRequest,
  type ListNotebookKernelSpecsResponse,
  NotebookKernelSpecService_CreateNotebookKernelSpec,
  NotebookKernelSpecService_DeleteNotebookKernelSpec,
  NotebookKernelSpecService_GetNotebookKernelSpec,
  NotebookKernelSpecService_ListNotebookKernelSpecs,
  NotebookKernelSpecService_UpdateNotebookKernelSpec,
  type UpdateNotebookKernelSpecRequest,
  type UpdateNotebookKernelSpecResponse,
} from '../gen/ai/h2o/notebook/v1/notebook_kernel_spec_service_pb';
import { kernelSpecsResponseMock } from './mocks/responses.mock';

const entityType = EntityType.KernelSpec;
const required = true;

export const actions = {
  [EntityActionType.Create]: {
    name: 'CreateNotebookKernelSpec',
    rpc: NotebookKernelSpecService_CreateNotebookKernelSpec,
    type: EntityActionType.Create,
    mockResponse: kernelSpecsResponseMock[0],
    responseKey: 'notebookKernelSpec',
    requestNameKey: 'notebookKernelSpecId',
    requestPayloadKey: 'notebookKernelSpec',
    requestNameKeyPrefix: 'notebookKernelSpec/',
    mockPath: '/notebookKernelSpec',
    entityType,
  } as EntityAction<NotebookKernelSpec, CreateNotebookKernelSpecRequest, CreateNotebookKernelSpecResponse>,
  [EntityActionType.Get]: {
    name: 'GetKernelSpec',
    rpc: NotebookKernelSpecService_GetNotebookKernelSpec,
    type: EntityActionType.Get,
    mockResponse: kernelSpecsResponseMock[0],
    responseKey: 'notebookKernelSpec',
    mockPath: '/notebookKernelSpec',
    entityType,
  } as EntityAction<NotebookKernelSpec, GetNotebookKernelSpecRequest, GetNotebookKernelSpecResponse>,
  [EntityActionType.List]: {
    name: 'ListKernelSpec',
    rpc: NotebookKernelSpecService_ListNotebookKernelSpecs,
    type: EntityActionType.List,
    mockResponse: kernelSpecsResponseMock,
    responseKey: 'notebookKernelSpecs',
    mockPath: '/notebookKernelSpecs',
    entityType,
  } as EntityAction<NotebookKernelSpec, ListNotebookKernelSpecsRequest, ListNotebookKernelSpecsResponse>,
  [EntityActionType.Update]: {
    name: 'UpdateKernelSpec',
    rpc: NotebookKernelSpecService_UpdateNotebookKernelSpec,
    type: EntityActionType.Update,
    mockResponse: kernelSpecsResponseMock,
    responseKey: 'notebookKernelSpec',
    mockPath: '/notebookKernelSpec',
    entityType,
  } as EntityAction<NotebookKernelSpec, UpdateNotebookKernelSpecRequest, UpdateNotebookKernelSpecResponse>,
  [EntityActionType.Delete]: {
    name: 'DeleteKernelSpec',
    rpc: NotebookKernelSpecService_DeleteNotebookKernelSpec,
    type: EntityActionType.Delete,
    mockResponse: kernelSpecsResponseMock,
    mockPath: '/notebookKernelSpec',
    entityType,
  } as EntityAction<NotebookKernelSpec, DeleteNotebookKernelSpecRequest, DeleteNotebookKernelSpecResponse>,
};

export const columns: IColumn[] = [
  {
    key: 'name',
    name: 'Name',
    fieldName: 'displayName',
    minWidth: 200,
    maxWidth: 350,
    onRender: onRenderDisplayName,
  },
  {
    key: 'disabled',
    name: 'Disabled',
    fieldName: 'disabled',
    minWidth: 80,
    maxWidth: 120,
    onRender: onRenderBooleanCell,
  },
  {
    key: 'kernelImage',
    name: 'Kernel Image',
    fieldName: 'kernelImage',
    minWidth: 140,
    maxWidth: 180,
    onRender: (...arg) => onRenderDataCell(...arg, getResourceId),
  },
  {
    key: 'kernelTemplate',
    name: 'Kernel Template',
    fieldName: 'kernelTemplate',
    minWidth: 140,
    maxWidth: 180,
    onRender: (...arg) => onRenderDataCell(...arg, getResourceId),
  },
  {
    key: 'actions',
    name: 'Actions',
    fieldName: 'actions',
    minWidth: 170,
    maxWidth: 170,
  },
];

export const fields: EntityField<NotebookKernelSpec>[] = [
  {
    name: 'name',
    type: EntityFieldType.IdOnDisplayAndId,
    label: 'Name',
    description: 'Kernel Spec resource name.',
    required,
  },
  {
    name: 'displayName',
    type: EntityFieldType.DisplayOnDisplayAndId,
    label: 'Display Name',
    description: 'Human-readable name of Kernel Spec',
    required,
  },
  {
    name: 'kernelImage',
    type: EntityFieldType.Text,
    label: 'Kernel Image',
    description: `
      The resource name of the KernelImage which will be used to create Notebook Lab Kernels.
      Format is "workspaces/*/kernelImages/*"
    `,
    required,
  },
  {
    name: 'kernelTemplate',
    type: EntityFieldType.Text,
    label: 'Kernel Template',
    description: `
      The resource name of the KernelTemplate which will be used to create Notebook Lab Kernels.
      Format is "workspaces/*/kernelTemplates/*"
    `,
    required,
  },
  {
    name: 'disabled',
    type: EntityFieldType.Boolean,
    label: 'Disabled',
    description: 'TBD',
  },
];

export const kernelSpecEntity: Entity<NotebookKernelSpec> = {
  type: entityType,
  name: 'KernelSpec',
  createButtonLabel: '',
  displayName: 'Notebook Kernel Spec',
  displayNameKey: 'name',
  requestNameKeyPrefix: '',
  actions,
  columns,
  fields,
  emptyModel: {
    name: `notebook-kernel-spec`,
    displayName: `Notebook Kernel Spec`,
    kernelImage: '',
    kernelTemplate: '',
    disabled: false,
  },
  canBeReordered: false,
};
